import React from 'react';
import * as S from './TableBody1.styled';
import { LogSheetEntry } from 'pages/Logs/redux';
import { convertDate23 } from 'global/utils';

const TableBody1 = function TableBody1({
  selectedSheet,
} : {
  selectedSheet: LogSheetEntry[],
}) {
  const headers = Object.keys(selectedSheet[0] || {}).filter((k) => k !== 'sheetName');
  const sheet: Object[] = JSON.parse(JSON.stringify(selectedSheet));

  if (sheet.length > 60) {
    sheet.splice(59, sheet.length - 59);
  }

  return (
    <tbody>
      { sheet.map((entry: any, i: number) => (
        <S.TR key={`tmptablebody${i}`}>
          { headers.map((h, k) => (
            <S.TD key={`tmpsheettd${h}${k}`}>
              <S.TDText>
                {
                  h === 'updatedAsOf' ? (<>{convertDate23(new Date(entry[h]))}</>) : (<>{entry[h]}</>)
                }
                {}
              </S.TDText>
            </S.TD>
          ))}
        </S.TR>
      ))}
    </tbody>
  );
};

export default TableBody1;
