import Color from 'color';
import { orange } from 'pages/App/components/Rooms.styled';
import { lFColor } from 'pages/components/LFHeader/LFHeader.styled';
import {
  borderColor,
  metricsPanelMobileHeight,
  mobileRes2,
  topBarColor2,
  topBarHeight
} from 'pages/styles';
import styled from 'styled-components';

export const metricsRes1 = '1125px';

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  z-index: 1;
  @media(max-width: ${metricsRes1}) {
    flex-direction: column;
  }
`;

export const MetricsCont1 = styled.div<{ isWeb?: boolean }>`
  width: max-content;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  @media(max-width: ${metricsRes1}) {
    position: ${({ isWeb }) => isWeb && 'fixed'};
    left: ${({ isWeb }) => isWeb && '-100%'};
  }
`;

const padding = '50px';
const padding2 = '45px';
// export const Header = styled.div<{ isWeb?: boolean }>`
//   width: 100%;
//   font-weight: 700;
//   font-size: 15px;
//   margin-top: 0px;
//   padding-bottom: 15px;
//   padding-right: calc(${padding});
//   border-bottom: 1px solid ${Color('lightgrey').lighten(0.075).toString()};
//   color: ${topBarColor2};
//   text-align: right;
//   @media(max-width: ${metricsRes1}) {
//     padding: 0 17px;
//     padding-bottom: 14px;
//     display: flex;
//     align-items: flex-end;
//   }
//   @media(max-width: ${mobileRes2}) {
//     padding-right: 19px;
//   }
// `;

export const Metrics = styled.div`
  width: 100%;
  padding: 0px ${padding};
  padding-left: calc(${padding} + 3px);
  border-top: 1px solid ${Color('lightgrey').lighten(0.03).toString()};
  border-bottom: 1px solid ${Color('lightgrey').lighten(0.03).toString()};
  padding-top: 28px;
  color: ${borderColor};
  max-height: calc(100svh - ${topBarHeight} - 111px);
  overflow-y: auto;
  position: relative;
  @media(max-width: ${mobileRes2}) {
    padding: 0px ${padding2};
    padding-top: 47px;
  }
`;

export const MetricsCont = styled.div`
  width: 100%;
  padding-bottom: 34px;
`;

export const MetricsGroup = styled.div`
  width: max-content;
  margin-left: auto;
  font-weight: 600;
  font-size: 14px;
  color: ${topBarColor2};
  padding-bottom: 10px;
  display: flex;
  align-items: flex-end;
  @media(max-width: ${mobileRes2}) {
    font-size: 13px;
  }
`;

export const FlowTypeCont = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 10px;
  margin-top: 2px;
  margin-left: auto;
  width: max-content;
`;

export const IconCont = styled.div<{ isSelected: boolean, bg?: string }>`
  display: flex;
  padding: 8px 9px;
  border: 1px solid ${Color('lightgrey').darken(0.075).toString()};
  color: ${Color('lightgrey').darken(0.29).toString()}; 
  background: ${({ isSelected, bg }) => isSelected && (bg || lFColor)};
  color: ${({ isSelected }) => isSelected && 'white'};
  border-color: ${({ isSelected, bg }) => isSelected && (bg || 'white')};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: ${({ isSelected }) => !isSelected && 'lightgrey'};
    color: ${({ isSelected }) => !isSelected && '#525252'};
  }
`;

export const Select = styled.input`
  height: 20px;
  width: 20px;
  margin: 0;
  margin-left: 12px;
  cursor: pointer;
`;

export const Select2 = styled(Select)`
  height: 19px;
  width: 19px;
  pointer-events: none;
`;

export const MetricsPanelMobile = styled.div`
  display: none;
  width: 100%;
  height: ${metricsPanelMobileHeight};
  padding: 10px 17px;
  padding-right: 18px;
  box-shadow: 0px -1px 2px 2px ${Color('lightgrey').darken(0.2).toString()};
  -webkit-box-shadow: 0px -1px 2px 2px ${Color('lightgrey').darken(0.2).toString()};
  -moz-box-shadow: 0px -1px 2px 2px ${Color('lightgrey').darken(0.2).toString()};
  align-items: flex-end;
  position: sticky;
  top: 0;
  z-index: 15;
  padding: 13px 19px;
  padding-right: 16px;
  background: white;
  @media(max-width: ${metricsRes1}) {
    display: flex;
  }
  @media(max-width: ${mobileRes2}) {
    box-shadow: 0px -1px 2px 2px rgba(0,0,0,0.37);
    -webkit-box-shadow: 0px -1px 2px 2px rgba(0,0,0,0.37);
    -moz-box-shadow: 0px -1px 2px 2px rgba(0,0,0,0.37);
    padding: 13px 14px;
    padding-right: 13px;
  }
`;

export const MetricsName = styled.div`
  width: max-content;
  margin-left: auto;
  font-size: 13px;
  color: #525252;
  padding: 15px 0 13px 0;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  &:hover {
    color: ${Color('#1685ec').darken(0.2).toString()};
  }
  @media(max-width: ${mobileRes2}) {
    font-size: 12px;
  }
`;

export const NamePart = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 10px;
  margin-left: 7px;
  color: ${Color('lightgrey').darken(0.125).toString()};
  &:hover {
    cursor: pointer;
    color: ${orange};
  }
`;

export const MetricsText = styled.div`
  font-weight: 700;
  font-size: 15px;
  color: ${topBarColor2};
  margin-left: auto;
  transform: translateY(0px);
`;

export const MetricsParts0 = styled.div`
  width: 100%;
  height: 100%;
  // display: flex;
  overflow: auto;
  overflow-x: visible;
  z-index: 2;
  max-height: max-content;
`;

export const MetricsParts = styled.div`
  overflow: visible;
`;

export const ChartsCont = styled.div<{ isRemove: boolean }>`
  width: 100%;
  overflow: visible;
  height: max-content;
  z-index: 1;
  opacity: ${({ isRemove }) => isRemove && '0'};
`;

export const LoadingCont = styled.div`
  height: calc(100svh - 340px);
  padding: 7px;
  width: 100%;
  position: relative;
`;

export const LoadingCont1 = styled.div`
  height: 130px;
  width: 200px;
  background: white;
  color: #727272;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
  border-radius: 3px;
`;
