import Color from 'color';
import { orange } from 'pages/App/components/Rooms.styled';
import { lFColor } from 'pages/components/LFHeader/LFHeader.styled';
import { topBarColor } from 'pages/styles';
import styled from 'styled-components';

const panelBorderColor = 'lightgrey';
const textColor = '#525252';

const tableSelectorRes1 = '708px';

export const Container = styled.div<{ isSingleOption: boolean }>`
  padding-bottom: 0px;
  position: relative;
  background: white;
  height: max-content;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 14px);
  max-width: 800px;
  z-index: 5;
  border-radius: 2px;
  @media(max-width: ${tableSelectorRes1}) {
    max-height: calc(100svh - 14px);
    max-width: ${({ isSingleOption }) => isSingleOption ? '300px' : '450px'};
  }
`;

export const Cont2 = styled(Container)`
  max-width: 300px;
  border-radius: 4px;
`;

export const LabelCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 40px;
  text-align: center;
`;

export const ButtonCont = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: auto;
  width: max-content;
  margin-bottom: 10px;
`;

export const OptionNo = styled.div`
  color: ${lFColor};
  font-size: 13px;
  padding: 10px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: ${orange};
  }
`;

export const BorderContainer = styled.div`
  border-bottom: 1px solid ${panelBorderColor};
  display: flex;
  height: 95%;
  max-height: 400px;
  height: 400px;
  @media(max-width: ${tableSelectorRes1}) {
    display: block;
    height: max-content;
    max-height: none;
  }
`;

const panelWidth = '200px';

export const FlexCont = styled.div`
  width: max-content;
  height: 100%;
  display: flex;
  @media(max-width: ${tableSelectorRes1}) {
    max-height: calc(55svh - 50px);
    height: max-content;
    width: 100%;
    padding-bottom: 10px;
  }
`;

export const SelectTable = styled.div`
  width: ${panelWidth};
  height: 100%;
  @media(max-width: ${tableSelectorRes1}) {
    width: 50%;
  }
`;

export const Header = styled.div`
  font-size: 13px;
  font-weight: 700;
  padding: 10.5px 12px;
  margin: auto;
  color: ${Color('#525252').darken(0.3).toString()};
  width: 100%;// calc(100% - 6px);
  background: ${Color(panelBorderColor).lighten(0.1).toString()};
  border-left: 1px solid ${Color(panelBorderColor).darken(0.1).toString()}; 
`;

export const RowsContainer = styled.div`
  margin-left: 12px;
  max-height: 90%;
  overflow-y: auto;
  direction: rtl;
`;

export const RowCont = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
  direction: ltr;
  cursor: pointer;
`;

export const RowCont1 = styled(RowCont)<{ isChosen: boolean }>`
  opacity: ${({ isChosen }) => isChosen && 0.2};
  cursor: pointer;
`;

export const CheckBoxDiv = styled.div`
  margin-left: 0px;
  display: flex;
  width: max-content;
`;

export const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin: 0;
  margin-top: 8px;
  background: ${Color(panelBorderColor).darken(0.05).toString()};
`;

export const RowName = styled.div`
  margin-left: 3px;
  margin-top: 1px;
  font-size: 12px;
  font-weight: 400;
  color: ${Color(textColor).darken(0.3).toString()};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  &:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }
`;

export const SelectedTable = styled.div<{ isAlone: boolean }>`
  width: ${panelWidth};
  height: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; 
  box-sizing: border-box;
  margin-left: -1px;
  @media(max-width: ${tableSelectorRes1}) {
    width: ${({ isAlone }) => isAlone ? '100%' : '50%'};
  }
`;

export const LogHeadersCont = styled.div`
  width: 100%;// calc(100% - 6px);
  margin: auto;
  border-left: 1px solid ${Color(panelBorderColor).lighten(0.05).toString()};
  @media(max-width: ${tableSelectorRes1}) {
    border-right: 1px solid ${Color(panelBorderColor).lighten(0.05).toString()};
  }
`;

export const LogHeader = styled.div`
  width: 100%;
  font-size: 13.5px;
  padding: 10px 5px;
  display: flex;
  gap: 7px;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${Color(topBarColor).lighten(0.8).toString()};
  }
`;

export const Circle = styled.div`
  height: 14px;
  width: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #525252;
  border-radius: 50%;
  margin-left: 4px;
  color: ${topBarColor};
`;

export const Icon = styled.div`
  margin-left: auto;
  height: 17px;
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RowsContainer2 = styled.div<{ isCollapsed: boolean}>`
  overflow-y: auto;
  direction: rtl;
  width: 100%;
  max-height: ${({ isCollapsed }) => isCollapsed ? '0px' : '170px'};
  border-top: 1px solid ${Color('#525252').lighten(0.2).toString()};
  border-bottom: 1px solid ${Color('#525252').lighten(0.2).toString()};
  border-bottom: ${({ isCollapsed }) => isCollapsed && 'none'};
  transition: all ease-out 250ms;
  padding-bottom: ${({ isCollapsed }) => isCollapsed ? '0px' : '6px'};
  @media(max-width: ${tableSelectorRes1}) {
    max-height: ${({ isCollapsed }) => isCollapsed ? '0px' : 'calc(calc(55svh - 50px) - 180px)'};
  }
`;

export const RowCont2 = styled.div<any>`
  padding: 4px 0;
  padding-left: 17px;
  direction: ltr;
  background: ${(props) => props.isSelected && Color(topBarColor).lighten(0.65).toString()};
  cursor: pointer;
  &:hover {
    background: ${(props) => !props.isSelected && Color(topBarColor).lighten(0.83).toString()};
  }
`;

export const CheckBox2 = styled(CheckBox)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin: 0;
  margin-top: 8px;
  margin-left: 3px;
  background: ${Color(panelBorderColor).darken(0.05).toString()};
`;

export const RowName2 = styled.div<any>`
  font-size: 11.2px;
  font-weight: 400;
  color: ${Color(textColor).darken(0.3).toString()};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
`;

export const ActualName = styled.div<any>`
  margin-top: -5px;
  font-size: 9px;
  font-weight: 700;
  color: ${Color(textColor).lighten(0.8).toString()};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
`;

export const MainButtonDiv = styled.div`
  height: max-content;
  width: 100%;
  padding: 10px;
  gap: 10px;
  display: flex;
  min-width: 0px;
  max-height: 400px;
`;

export const InputPart = styled.div`
  height: 40px;
  flex: 1;
  margin-left: auto;
  max-width: 160px;
  position: relative;
`;

export const Info = styled.div<{ isError: boolean }>`
  font-size: 10px;
  color: ${({ isError }) => isError ? 'red' : Color('lightgrey').darken(0.1).toString()};
  position: absolute;
  top: 4px;
  padding-left: 7px;
  pointer-events: none;
`;

export const Input = styled.input<{ isError: boolean }>`
  height: 100%;
  width: 100%;
  padding: 0px 7px;
  padding-top: 13px;
  border-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex: 1;
  border-radius: 3px;
  border: ${({ isError }) => isError ? '1px solid red' : '1px solid grey'};
  font-weight: 700;
  font-size: 12px;
  min-width: 0;
  letter-spacing: 0.5px;
  &:focus {
    outline: none;
    border: 2px solid black;
    border-color: ${({ isError }) => isError && 'red'};
  }
`;

export const MainButton = styled.div`
  color: ${Color(lFColor).lighten(0.05).toString()};
  background: ${Color(lFColor).lighten(1.8).toString()};
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; 
  box-sizing: border-box;
  padding: 11px 20px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  width: max-content;
  height: max-content;
  cursor: pointer;
  &:hover {
    background: ${Color(lFColor).lighten(0.55).toString()};
    color: ${Color(lFColor).darken(0.35).toString()};
  }
`;

export const TablePreview = styled.div`
  overflow: auto;
  flex: 1;
  display: flex;
  max-height: 400px;
  flex-direction: column;
  border-left: 1px solid ${Color(panelBorderColor).darken(0.1).toString()}; 
  @media(max-width: ${tableSelectorRes1}) {
    flex: none;
    width: 100%;
    max-height: 40svh;
  }
`;

export const Header2 = styled(Header)`
  border-left: none; 
`;
