import React, { useEffect, useState } from 'react';
import * as S from './Navigation.styled';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectIsScrollScreen,
  selectIsStaySignedIn,
  selectPassword,
  selectRooms,
  selectUserEmail, setDisplayRooms, setRooms,
} from 'pages/App/redux';
import { toast } from 'react-toastify';
import {
  expireCreds,
  login,
  logout,
  selectToken, selectUsername,
} from 'redux/global';
import Loader from 'pages/components/Loader/Loader';
import { getHotel, verifyAuth } from './redux';
import { moveSideBar } from './utils';
import * as io from 'socket.io-client';
import {
  selectDebugText,
  selectHotelData,
  selectModalParts,
  setHotelData,
  setIsSocketConnected,
  setSocket
} from 'pages/redux';
import { Helmet } from 'react-helmet';
import { setIsStaff } from 'pages/Homepage/redux';
import Footer from './Footer/Footer';
import { useLocation } from 'react-router-dom';
import { selectLogs, setLogs } from 'pages/Logs/redux';
import { useLocalRoomUpdate } from 'pages/App/hooks';
import InfoBar from './components/InfoBar';
import LFHeader from 'pages/components/LFHeader/LFHeader';
import LBHeader from 'pages/components/LBHeader/LBHeader';
import SignInModal from 'pages/Homepage/modals/SignInModal';
import Modal from 'pages/components/Modal/Modal';
import LFFooter from 'pages/LFHomepage/LFFooter/LFFooter';
import LBFooter from 'pages/LBHomepage/LBFooter/LBFooter';
import { lFColor } from 'pages/components/LFHeader/LFHeader.styled';
import { topBarColor2 } from 'pages/styles';
import { useLogs } from 'pages/Logs/hooks';
import { usePath } from 'pages/hooks';

const socket = io.connect(
  window.location.hostname === (process.env.REACT_APP_MOBILE_APP_URL || '').split(':8200').join('')
    .split('http://').join('').split('/').join('') ? process.env.REACT_APP_MOBILE_APP_URL || '' :
    (process.env.REACT_APP_API || ''),
  { transports: ['polling'] }
);

const Navigation = function Navigation({ children }:{ children: any}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isShowSignIn, setIsShowSignIn] = useState<boolean>(false);
  const [currentClient, setCurrentClient] = useState<string | number>('');
  const isStaySignedIn = useAppSelector(selectIsStaySignedIn);
  const email = useAppSelector(selectUserEmail);
  const password = useAppSelector(selectPassword);
  const token = useAppSelector(selectToken);
  const rooms = useAppSelector(selectRooms);
  const savedUsername = useAppSelector(selectUsername);
  const logs = useAppSelector(selectLogs);
  const isScrollScreen = useAppSelector(selectIsScrollScreen);
  const debugText = useAppSelector(selectDebugText);
  const modalParts = useAppSelector(selectModalParts);
  const hotelData = useAppSelector(selectHotelData);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { addLocalRoom, editLocalRoom, deleteLocalRoom } = useLocalRoomUpdate();
  const {
    deleteLocalLog, updateLocalLog, fetchBranchFiles, updateLocalBranch, deleteLocalBranch
  } = useLogs();
  const { isHomePath } = usePath();
  const isLodgeFirst = process.env.REACT_APP_VIEW === 'LodgeFirst';

  const authorizeAndLogin = () => {
    const loginUser = async () => {
      setLoading(true);
      const hotelId = await dispatch(login({ email: email.toLowerCase(), password }));
      if (hotelId) {
        const res = await dispatch(getHotel(hotelId));
        setLoading(false);
        if (res.status === 'success') {
          dispatch(setHotelData(res.data[0]));
          return;
        }
        setLoading(false);
        toast(res.data, { type: 'error' });
        return;
      }
      setLoading(false);
      toast('Could not log in', { type: 'error' });
    };

    const authorize = async () => {
      setLoading(true);
      const res = await dispatch(verifyAuth());
      setLoading(false);
      if (res.status === 'success') {
        setCurrentClient(hotelData?.id || '');
        dispatch(expireCreds(res.data as number));
        return;
      }
      if (isStaySignedIn) {
        loginUser();
      } else {
        dispatch(logout());
        setIsShowSignIn(true);
      }
    };

    const checkIfTokenAvailable = async () => {
      if (token) {
        authorize();
      } else {
        setIsShowSignIn(true);
      }
    };

    dispatch(setSocket(socket));
    if (isLodgeFirst) {
      checkIfTokenAvailable();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setRooms(null));
      dispatch(setDisplayRooms(null));
    };
  }, []);

  useEffect(() => {
    if (hotelData?.id && currentClient !== hotelData?.id) {
      socket.emit('join_room', `room${hotelData.id}`);
      authorizeAndLogin();
    }
  }, [isShowSignIn, hotelData]);

  useEffect(() => {
    if (!hotelData?.id) {
      return;
    }

    socket.on('connect_error', () => {
      dispatch(setIsSocketConnected(false));
    });

    socket.on('connect', () => {
      dispatch(setIsSocketConnected(true));
    });

    socket.on('get_added_room', (room) => {
      addLocalRoom(room);
    });

    socket.on('get_edited_room', (room) => {
      editLocalRoom([room]);
    });

    socket.on('get_booked_room', (room) => {
      editLocalRoom(room);
    });

    socket.on('get_deleted_room', (id) => {
      deleteLocalRoom(id);
    });

    socket.on('get_revoked_staff', (username) => {
      if (savedUsername?.replace(/\s+/g, '') === username.replace(/\s+/g, '')) {
        toast('Your access is denied', { type: 'error' });
        dispatch(setIsStaff(false));
        dispatch(logout());
      }
    });

    socket.on('get_added_log', (logEntry) => {
      if (logs && !logs.find((log) => log.id.toString() === logEntry.id.toString())) {
        const newLogs = [...(logs || []), logEntry];
        dispatch(setLogs([...newLogs]));
      }
    });

    socket.on('get_deleted_log', (logId) => {
      if (logs) {
        deleteLocalLog(logId);
      }
    });

    socket.on('get_updated_log', (log) => {
      if (logs) {
        updateLocalLog(log);
      }
    });

    socket.on('get_updated_branchFiles', () => {
      if (logs) {
        fetchBranchFiles(true);
      }
    });

    socket.on('get_updated_branch', (branch) => {
      updateLocalBranch(branch);
    });

    socket.on('get_deleted_branch', (branch) => {
      deleteLocalBranch(branch);
    });

    return () => { socket.off(); };
  }, [socket, rooms, logs, hotelData]);

  useEffect(() => {
    document.getElementById('main_cont')?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [isScrollScreen, location]);

  useEffect(() => {
    if (isLodgeFirst && !token) {
      setIsShowSignIn(true);
    }
  }, [token]);

  const isFitScreen = isLodgeFirst && (location.pathname.includes('logs') || location.pathname.includes('metrics') ||
    location.pathname.includes('/reservations'));
  const isShowSignInMain = isShowSignIn && !isHomePath;

  return (
    <S.MainContainer>
      <Helmet>
        <title>{isLodgeFirst ? 'Lodge First' : 'Lodger Bee'}</title>
        <meta name="theme-color" content={isLodgeFirst ? lFColor : topBarColor2} />
        <meta name="msapplication-navbutton-color" content={isLodgeFirst ? lFColor : topBarColor2} />
        <meta name="apple-mobile-web-app-status-bar-style" content={isLodgeFirst ? lFColor : topBarColor2} />
      </Helmet>
      {
        modalParts && (
          <Modal
            component={modalParts.component}
            exitOnBgClick={modalParts.exitOnBgClick}
          />
        )
      }
      {
        isLodgeFirst ? (
          <LFHeader />
        ) : (<LBHeader />)
      }
      {loading && <Loader />}
      <>
        {
          isShowSignInMain ? (
            <Modal
              component={(
                <SignInModal onExit={() => setIsShowSignIn(false)} />
              )}
            />
          ) : (
            <>
              <InfoBar />
              <S.Container id="main_cont" isFitScreen={isFitScreen}>
                <S.Page>
                  <S.DebugBg id="debug_bg">
                    {
                      debugText.map((d, i) => <div key={`debug_${i}`}>{d}</div>)
                    }
                  </S.DebugBg>
                  <S.Bg
                    id="main_bg"
                    onClick={() => {
                      moveSideBar(true);
                    }}
                  />
                  <S.BottomRightPanel isFitScreen={isFitScreen || location.pathname.includes('/cart')
                    || isHomePath}
                  >
                    {children}
                  </S.BottomRightPanel>
                </S.Page>
                {
                  (!isLodgeFirst && !isHomePath) && <Footer />
                }
                {
                  (!isLodgeFirst && location.pathname === '/') && <LBFooter />
                }
                {
                  (isLodgeFirst && isHomePath) && <LFFooter />
                }
              </S.Container>
            </>
          )
        }
      </>
    </S.MainContainer>
  );
};

export default Navigation;
