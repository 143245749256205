import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as S from './Social.styled';
import { useAppSelector } from 'redux/hooks';
import { selectHotelData } from 'pages/redux';

const Social = function Social() {
  const hotelData = useAppSelector(selectHotelData);

  return (
    <S.Container>
      {
        hotelData?.linkedin && (
          <S.IconContainer
            onClick={() => window.open(`https://linkedin.com/company/${hotelData?.linkedin}/`, '_blank')}
          >
            <S.Icon>
              <FontAwesomeIcon icon={faLinkedinIn as IconDefinition} size="3x" />
            </S.Icon>
          </S.IconContainer>
        )
      }
      {
        hotelData?.facebook && (
          <S.IconContainer
            onClick={() => window.open(`https://facebook.com/${hotelData?.facebook}/`, '_blank')}
          >
            <S.Icon>
              <FontAwesomeIcon icon={faFacebookF as IconDefinition} size="3x" />
            </S.Icon>
          </S.IconContainer>
        )
      }
      {
        hotelData?.twitter && (
          <S.IconContainer
            onClick={() => window.open(`https://twitter.com/topdevafrica1/${hotelData?.twitter}`, '_blank')}
          >
            <S.Icon>
              <FontAwesomeIcon icon={faTwitter as IconDefinition} size="3x" />
            </S.Icon>
          </S.IconContainer>
        )
      }
      {
        hotelData?.instagram && (
          <S.IconContainer
            isLast
            target="_blank"
            onClick={() => window.open(`https://instagram.com/${hotelData?.instagram}/`, '_blank')}
          >
            <S.Icon>
              <FontAwesomeIcon icon={faInstagram as IconDefinition} size="3x" />
            </S.Icon>
          </S.IconContainer>
        )
      }
    </S.Container>
  );
};

export default Social;
