import styled from 'styled-components';
import Color from 'color';
import { topBarColor } from 'pages/styles';

export const headerColor = '#212121';
export const textColor2 = '#ababab';
export const textColor = '#ababab';

const mobileRes = '1010px';
export const FooterContainer = styled.div<any>`
  padding: 4.5em 12vw;
  padding-right: 17vw;
  padding-bottom: 8em;
  width: 100%;
  background: ${Color(topBarColor).darken(0.775).toString()};
  color: ${Color(textColor2).lighten(0).toString()};
  position: relative;
  z-index: 12;
  margin-top: 0px;
  display: flex;
  @media(max-width: 1300px) {
    padding: 4.5em 9.5vw;
    padding-right: 17vw;
    padding-bottom: 8em;
  }
  @media(max-width: ${mobileRes}) {
    flex-direction: column;
    padding: 50px 0px;
    padding-bottom: 130px;
  }
`;

export const FooterSection1 = styled.div<any>`
  text-align: left;
  display: block;
  text-align: center;
  @media(max-width: ${mobileRes}) {
    width: 85vw;
    overflow: hidden;
    margin: auto;
  }
`;

export const JoinUs2 = styled.div`
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: center;
`;

export const FooterSection2 = styled.div<any>`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  text-align: center;
  @media(max-width: ${mobileRes}) {
    margin-top: 30px;
    position: relative;
  }
`;

export const Feedback = styled.div<any>`
  margin-top: 20px;
  font-size: 30px;
  font-weight: 200;
`;

export const FooterSection = styled.div`
  width: max-content;
  margin-left: auto;
  display: inline-block;
  text-align: center;
  @media(max-width: ${mobileRes}) {
    margin: auto;
    margin-top: 60px;
  }
`;

export const FooterSection22 = styled.div`
  margin-top: 60px;
  @media(max-width: ${mobileRes}) {
    margin-top: 30px;
  }
`;

export const FooterRightSection2 = styled.div`
  width: max-content;
  margin-left: 0em;
  display: inline-block;
`;

export const FooterRightSection3 = styled.div`
  width: max-content;
  margin-left: 0em;
  display: flex;
`;

export const Label = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
  height: 23px;
  font-size: calc(16px + 0.1vw);
  color: ${textColor};
`;

export const Link = styled.div`
  font-size: calc(14px + 0.1vw);
  text-decoration: none;
  color: ${Color(textColor2).darken(0.15).toString()};
  display: block;
  cursor: pointer;
  &:hover {
    color: white;
  }
`;

export const Copyright = styled.div`
  font-size: 13px;
  min-width: 0;
  color: ${textColor};
  display: block;
  font-weight: 400;
  width: max-content;
  max-width: 90%;
  text-align: center;
  bottom: 15px;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  @media(max-width: ${mobileRes}) {
    position: relative;
    margin: auto;
    right: 0;
    transform: translateX(0%);
    margin-top: 45px;
    width: max-content;
  }
`;

export const LangContainer = styled.div`
  position: relative;
  right: 2rem;
  bottom: 1rem;
  padding-top: 4em;
`;

export const IconsSection = styled.div`
  padding-top:2.5em;
`;

export const JoinUs = styled.div`
  padding: 0;
  font-size: 18px;
  font-weight: 700;
`;
