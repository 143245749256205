import { LogKey, logGroupsRaw } from 'pages/Logs/Logs';
import { Log, useLogs } from 'pages/Logs/hooks';
import { selectLogsChecked, setLogsChecked } from 'pages/Logs/redux';
import { selectHotelData, selectMetricTime } from 'pages/redux';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectMetricChecked, selectMetricsFlowType } from './redux';

export const metrics = {
  Balances: ['Active audits', 'Hospitality', 'Branches', 'All audits'] as const,
  Counts: ['Active audits2', 'Online visitor', 'Walk-in', 'All audits2'] as const
};

export type MetricsKey = typeof metrics['Balances'][number] | typeof metrics['Counts'][number];

export const useMetrics = () => {
  const { logs } = useLogs();
  const checked = useAppSelector(selectMetricChecked);
  const logsChecked = useAppSelector(selectLogsChecked);
  const { startDate, endDate } = useAppSelector(selectMetricTime) || {};
  const flowType = useAppSelector(selectMetricsFlowType);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const hotelData = useAppSelector(selectHotelData);

  const { branches } = hotelData || {};
  const logGroups: Record<LogKey, string[]> = { ...logGroupsRaw };
  const addBranches = branches?.map((b) => b.name);
  logGroups.Branches = [...(addBranches || []), ...logGroups.Branches];
  const allAudits: string[] = [...Object.entries(logGroups).map(([k]) => k), 'Show all'];
  Object.entries(logGroups).forEach(([, v]) => allAudits.push(...v));
  const dateFilteredLogs = [...(logs || [])].filter((log) => (!startDate || !endDate)
  || (startDate && endDate && +new Date(log.date) >= +new Date(startDate)
  && +new Date(log.date) <= +new Date(endDate)));

  const getTotal = (logsChecked1: string[]) => {
    let total = 0;
    logsChecked1.forEach((bA) => {
      const thisTotal = dateFilteredLogs.reduce((a, b) => {
        const t = flowType === '-' && Number(b.value) < 0 ? Number(b.value) :
          flowType === '+' && Number(b.value) > 0 ? Number(b.value) :
            flowType === 'both' ? Number(b.value) || 0 : 0;
        return a + (bA.includes(b.type) ? t : 0);
      }, 0);
      total += thisTotal;
    });
    return total;
  };

  const getCount = (logsChecked1: string[]) => {
    let total = 0;
    logsChecked1.forEach((bA) => {
      const thisTotal = dateFilteredLogs.reduce((a, b) => a + (bA.includes(b.type) ? 1 : 0), 0);
      total += thisTotal;
    });
    return total;
  };

  type TimeMetric = {
    total: number,
    checked: string[],
    onClick: Function,
    name: MetricsKey,
  }

  const onClickViewMetricAudits = () => {
    flowType === '-' ? history.push('/logs/svg1ere2xxyv') :
      flowType === '+' ? history.push('/logs/svg1ere1xxyv') :
        flowType === 'both' ? history.push('/logs/svg1ere3xxyv') :
          history.push('/logs/svg1ere4xxyv');
  };

  const timeMetrics: Record<MetricsKey, TimeMetric> = {
    'Active audits': {
      name: 'Active audits',
      total: getTotal(logsChecked),
      checked: logsChecked,
      onClick: () => {
        onClickViewMetricAudits();
      }
    },
    Hospitality: {
      name: 'Hospitality',
      total: getTotal(logGroups.Hospitality),
      checked: logGroups.Hospitality,
      onClick: () => {
        dispatch(setLogsChecked(['Hospitality', ...logGroups.Hospitality]));
        onClickViewMetricAudits();
      }
    },
    Branches: {
      name: 'Branches',
      total: getTotal([...logGroups.Branches]),
      checked: [...logGroups.Branches],
      onClick: () => {
        dispatch(setLogsChecked(['Branches', ...logGroups.Branches]));
        history.push('/logs/svg1ere1xxyv');
      }
    },
    'All audits': {
      name: 'All audits',
      total: getTotal(allAudits),
      checked: allAudits,
      onClick: () => {
        dispatch(setLogsChecked(allAudits));
        onClickViewMetricAudits();
      }
    },
    'Active audits2': {
      name: 'Active audits2',
      total: getCount(logsChecked),
      checked: logsChecked,
      onClick: () => {
        onClickViewMetricAudits();
      }
    },
    'Online visitor': {
      name: 'Online visitor',
      total: getCount(['Online visitor']),
      checked: ['Online visitor'],
      onClick: () => {
        dispatch(setLogsChecked(['Online visitor']));
        onClickViewMetricAudits();
      }
    },
    'Walk-in': {
      name: 'Walk-in',
      total: getCount(['Walk in']),
      checked: ['Walk in'],
      onClick: () => {
        dispatch(setLogsChecked(['Walk in']));
        onClickViewMetricAudits();
      }
    },
    'All audits2': {
      name: 'All audits2',
      total: getCount(allAudits),
      checked: allAudits,
      onClick: () => {
        dispatch(setLogsChecked(allAudits));
        onClickViewMetricAudits();
      }
    },
  };

  const checkFilteredLogs = dateFilteredLogs.filter((l) =>
    timeMetrics[checked as MetricsKey].checked.includes(l.type));

  const checkedMetric = timeMetrics[checked as MetricsKey];

  // eslint-disable-next-line no-unused-vars
  const getMetricValue = (criterea: (log: Log) => boolean, logToUse?: string) => {
    const l = logToUse === 'logs' ? logs : checkFilteredLogs;
    return (l || []).reduce((a, b) => {
      const toAdd = flowType === '+' ? (Number(b.value) || 0) > 0 ? Number(b.value) : 0 :
        flowType === '-' ? (Number(b.value) || 0) < 0 ? Number(b.value) : 0 :
          flowType === 'both' ? (Number(b.value) || 0) : 1;
      return a + (criterea(b) ? toAdd : 0);
    }, 0);
  };

  return {
    logGroups,
    addBranches,
    timeMetrics,
    checkedMetric,
    dateFilteredLogs,
    checkFilteredLogs,
    getMetricValue,
    flowType,
    metricChecked: checked,
  };
};
