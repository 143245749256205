import Color from 'color';
import { topBarColor } from 'pages/styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 0px;
  transition: height ease-out 250ms;
  background: ${Color(topBarColor).lighten(0.85).toString()};
  border-bottom: 1px solid ${Color(topBarColor).lighten(0.7).toString()};
  display: none;
`;

export const Cont1 = styled.div`
  flex: 1;
  transition: opacity linear 80ms;
  display: flex;
  align-items: center;
  padding: 0 15px;
`;
