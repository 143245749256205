import React, { useEffect, useRef, useState } from 'react';
import * as S from './LogTable.styled';
import { useLogs } from '../hooks';
import { convertDate } from 'global/utils';
import Room from '@mui/icons-material/MeetingRoom';
import Branches from '@mui/icons-material/AccountTree';
import ReactPaginate from 'react-paginate';
import './styles.css';
import MediaQuery from 'react-responsive';
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';
import More from '@mui/icons-material/MoreHoriz';
import MoreVert from '@mui/icons-material/MoreVert';
import Bin from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectHotelData, selectMetricTime, selectSocket } from 'pages/redux';
import {
  editLog,
  selectLogsChecked,
  selectRecentLog,
  selectSingleLogTime,
  setLogsChecked,
  setRecentLog,
  setSingleLogTime
} from '../redux';
import Modal from 'pages/components/Modal/Modal';
import DeleteLog from '../modals/DeleteLog';
import Close from '@mui/icons-material/Close';
import Loader from 'pages/components/Loader/Loader';
import ModalAction from 'pages/components/Modal/views/ModalAction';
import { toast } from 'react-toastify';
import { selectPermission, selectUsername } from 'redux/global';

const LogTable = (
  {
    checked, triggerAllItems, triggerCashIn, triggerCashOut
  }
  :
  { checked: string[], triggerAllItems: boolean, triggerCashIn: boolean, triggerCashOut: boolean }
) => {
  const socket = useAppSelector(selectSocket);
  const recentLog = useAppSelector(selectRecentLog);
  const hotelData = useAppSelector(selectHotelData);
  const [cashIn, setCashIn] = useState<boolean>(true);
  const [cashOut, setCashOut] = useState<boolean>(true);
  const [allItems, setAllItems] = useState<boolean>(true);
  const [isUseMetricTime, setIsUseMetricTime] = useState<boolean>(false);
  const [isShowDeleteLog, setIsShowDeleteLog] = useState<boolean>(false);
  const [isShowEditSave, setIsShowEditSave] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addPages, setAddPages] = useState<boolean>(localStorage.getItem(
    'add_pages'
  ) === 'yes');
  const [page, setPage] = useState<number>(1);
  const [highlightLog, setHighlightLog] = useState<number>(-1);
  const [editAmount, setEditAmount] = useState<string>('');
  const [editAmountError, setEditAmountError] = useState<string>('');
  const [width, setWidth] = useState<number>(0);
  const metricT = useAppSelector(selectMetricTime);
  const singleLogTime = useAppSelector(selectSingleLogTime);
  const username = useAppSelector(selectUsername);
  const logsChecked = useAppSelector(selectLogsChecked);
  const permission = useAppSelector(selectPermission);
  const { logs, updateLocalLog, logGroups } = useLogs();
  const headers = Object.entries(logs?.[0] || {}).map(([k]) => k).filter((l) => l !== 'id'
    && l !== 'updatedAsOf' && l !== 'updatedBy' && l !== 'field1' && l !== 'field2');
  const { action }: any = useParams();
  const dispatch = useAppDispatch();

  const auditsMax = 40;

  const { startDate, endDate } = (singleLogTime || metricT || {});

  const getPermissionBasedDisplayLogs = () => {
    return (permission || 0) >= 3 ? (logs || []) : (logs || []).filter(
      (l) => l.message.includes(`|${username}|` || '')
    );
  };

  const dateSortedLogs = [...getPermissionBasedDisplayLogs()];
  dateSortedLogs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const getSelectLogs = (checked1: string[]) => {
    return (dateSortedLogs || []).filter((log) => checked1.includes(log.type));
  };

  const dateFilteredLogs = isUseMetricTime ? [...(dateSortedLogs || [])].filter((log) => {
    return (!startDate || !endDate)
      || (startDate && endDate && +new Date(log.date) >= +new Date(startDate)
      && +new Date(log.date) <= +new Date(endDate));
  }) : (dateSortedLogs || []);

  const getAllDisplayLogs = () => {
    return dateFilteredLogs.filter((log) => checked.includes(log.type)
      && (allItems || (cashIn && Number(log.value) > 0) || (cashOut && Number(log.value) < 0)));
  };

  const startLog = (auditsMax * (page - 1));

  const displayLogs = getAllDisplayLogs().splice(startLog, auditsMax);

  const rem = getAllDisplayLogs().length % auditsMax ? 1 : 0;
  const pagesCount = Math.trunc(getAllDisplayLogs().length / auditsMax) + rem;

  const observedDiv = useRef(null);
  const nonUsedRef = useRef(null);

  useEffect(() => {
    if (!observedDiv?.current) {
      return;
    }
    const observerCallback: any = (entries: ResizeObserverEntry[]) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        const { current } = (observedDiv as any) || {};
        if (current && current.offsetWidth !== width) {
          const newWidth = current.offsetWidth;
          setWidth(newWidth);
          const otherPagesTotal = document.getElementById('other_pages_t');
          if (otherPagesTotal) {
            otherPagesTotal.style.minWidth = `${newWidth}px`;
          }
          const thisPageTotal = document.getElementById('this_page_t');
          if (thisPageTotal) {
            thisPageTotal.style.minWidth = `${newWidth}px`;
          }
        }
      });
    };

    const resizeObserver = new ResizeObserver(observerCallback);
    resizeObserver.observe(observedDiv.current);

    const otherPagesTotal = document.getElementById('other_pages_t');
    if (otherPagesTotal) {
      otherPagesTotal.style.minWidth = `${width}px`;
    }
    const thisPageTotal = document.getElementById('this_page_t');
    if (thisPageTotal) {
      thisPageTotal.style.minWidth = `${width}px`;
    }

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [observedDiv.current, page, addPages, cashIn, cashOut, allItems, isUseMetricTime, checked]);

  useEffect(() => {
    if (recentLog) {
      setIsUseMetricTime(false);
      setAllItems(true);
      setCashIn(true);
      setCashOut(true);
      const thisLog = (logs || []).find(
        (l) => l.id.toString() === recentLog.toString()
      );
      if (thisLog) {
        const newChecked = [...checked.filter((c) => c !== thisLog.type), thisLog.type];
        const thisIndex = getSelectLogs(newChecked).findIndex((l) => l.id.toString() === recentLog
          .toString());
        if (thisIndex > -1) {
          const thisPage = Math.ceil(Number(thisIndex) / auditsMax);
          dispatch(setLogsChecked(newChecked));
          setPage(thisPage < 1 ? 1 : thisPage);
        }
      }
    }
  }, [recentLog, logs]);

  useEffect(() => {
    setIsUseMetricTime(false);
    setAllItems(true);
    setCashIn(true);
    setCashOut(true);
  }, [triggerAllItems]);

  useEffect(() => {
    setIsUseMetricTime(false);
    setCashIn(true);
  }, [triggerCashIn]);

  useEffect(() => {
    setIsUseMetricTime(false);
    setCashOut(true);
  }, [triggerCashOut]);

  useEffect(() => {
    dispatch(setRecentLog(''));
    if (action) {
      const action1 = action.replace('svg1ere', '').replace('xxyv', '');
      setIsUseMetricTime(true);
      if (Number(action1) === 0) {
        return () => {
          dispatch(setSingleLogTime(null));
        };
      }
      localStorage.setItem('add_pages', 'yes');
      setAddPages(true);
      setAllItems(false);
      if (Number(action1) === 1) {
        setCashOut(false);
      }
      if (Number(action1) === 2) {
        setCashIn(false);
      }
      if (Number(action1) === 4) {
        setAllItems(true);
      }
    }
    return () => {
      dispatch(setSingleLogTime(null));
    };
  }, []);

  const formattedEntry = (entry: string) => {
    const numberEntry = Number(entry);
    if (numberEntry || numberEntry === 0 || entry === 'N/A') {
      if (numberEntry === 0) {
        return [<span>0</span>];
      }
      if (entry === 'N/A') {
        return [<span>N/A</span>];
      }
      if (numberEntry > 0) {
        return [
          <S.CashIn>{`₦${numberEntry.toLocaleString()}`}</S.CashIn>
        ];
      }
      return [
        <S.CashOut>{`₦${numberEntry.toLocaleString()}`}</S.CashOut>
      ];
    }
    const splitWords: any[] = entry.split(/(?:\||&P&|\$|#|\^|_|!|&)+/);
    const seperators = {
      '|': (text: string) => (
        <S.Username>
          {text}
        </S.Username>
      ),
      '&': (text: string) => (
        <S.Bold>
          {text}
        </S.Bold>
      ),
      $: (text: string) => (
        <S.Room>
          <Room fontSize="small" sx={{ scale: '0.65' }} />
          <S.RoomText>{text}</S.RoomText>
        </S.Room>
      ),
      '!': (text: string) => (
        <S.External>
          <Branches fontSize="small" sx={{ scale: '0.65' }} />
          <S.ExternalText>{text}</S.ExternalText>
        </S.External>
      ),
      '^': (text: string) => (
        <span style={{ color: '#eb364b' }}>
          {text}
        </span>
      ),
      '&P&': (text: string) => (
        <S.Bold>
          {`₦${Number(text).toLocaleString()}`}
        </S.Bold>
      )
    };

    Object.entries(seperators).forEach(([key, value]) => {
      const eArr = entry.split('');
      const inds: number[][] = [];
      let pos = 'start';
      let ind: number[] = [];

      eArr.forEach((e, i) => {
        const thisEntryToKey = `${eArr[i - 2]}${eArr[i - 1]}${e}`;
        const isSeperatorKey = thisEntryToKey === key;
        if ((e === key || isSeperatorKey) && pos === 'start') {
          ind = [];
          ind.push(i + 1);
          pos = 'end';
          return;
        }
        if ((e === key || isSeperatorKey) && pos === 'end') {
          if (e === key) {
            ind.push(i);
          } else {
            ind.push(i - 2);
          }
          inds.push(ind);
          pos = 'start';
        }
      });

      inds.forEach((i) => {
        // The words between each seperator in DB log message
        const word = entry.substring(i[0], i[1]);
        const splitWordInd = splitWords.indexOf(word);
        splitWords[splitWordInd] = value(splitWords[splitWordInd]);
      });
      splitWords.forEach((w, i) => {
        if (w === '%') {
          splitWords[i] = <div />;
        }
      });
    });
    return splitWords;
  };

  const FormatLogs = () => (
    <S.FormatLogs>
      <S.FormatSet
        onClick={() => {
          if (!isUseMetricTime && Boolean(allItems && displayLogs.length === 0)) {
            return;
          }
          setIsUseMetricTime(!isUseMetricTime);
        }}
      >
        <S.All>
          Time
          <MediaQuery minWidth={Number(S.logSmallRes2.replace('px', ''))}>
            {' '}
            range
          </MediaQuery>
        </S.All>
        <S.Select
          type="checkbox"
          checked={isUseMetricTime}
          disabled={!isUseMetricTime && Boolean(allItems && displayLogs.length === 0)}
          readOnly
        />
      </S.FormatSet>
      <S.FormatSet
        onClick={() => {
          if (allItems && displayLogs.length === 0) return;
          setAllItems(false);
          setCashIn(!cashIn);
          if (cashIn) {
            setCashOut(true);
          }
        }}
      >
        <S.ShowCashIn>
          <MediaQuery minWidth={295}>
            Cash-
          </MediaQuery>
          In
        </S.ShowCashIn>
        <S.Select
          type="checkbox"
          checked={cashIn}
          disabled={Boolean(allItems && displayLogs.length === 0)}
          readOnly
        />
      </S.FormatSet>
      <S.FormatSet
        onClick={() => {
          if ((allItems && displayLogs.length === 0)) return;
          setAllItems(false);
          setCashOut(!cashOut);
          if (cashOut) {
            setCashIn(true);
          }
        }}
      >
        <S.ShowCashOut>
          <MediaQuery minWidth={Number(S.logMiniRes.replace('px', ''))}>
            Cash-
          </MediaQuery>
          Out
        </S.ShowCashOut>
        <S.Select
          type="checkbox"
          checked={cashOut}
          disabled={Boolean(allItems && displayLogs.length === 0)}
          readOnly
        />
      </S.FormatSet>
      <S.FormatSet
        onClick={() => {
          if (allItems && displayLogs.length === 0) {
            return;
          }
          setAllItems(!allItems);
          if (!allItems) {
            setCashIn(true);
            setCashOut(true);
          }
        }}
      >
        <S.All>All</S.All>
        <S.Select
          type="checkbox"
          checked={allItems}
          disabled={Boolean(allItems && displayLogs.length === 0)}
          readOnly
        />
      </S.FormatSet>
    </S.FormatLogs>
  );

  const logsHaveCashGroup = Boolean([...logGroups.Hospitality, ...logGroups.Branches]
    .filter((g) => !checked.includes(g))
    .length !== logGroups.Hospitality.length + logGroups.Branches.length);

  const thisPageTotal = displayLogs.reduce((a, b) => a + (Number(b.value) || 0), 0);
  const allPagesTotal = getAllDisplayLogs().reduce((a, b) => a + (Number(b.value) || 0), 0);
  const remainderTotal = allPagesTotal - thisPageTotal;
  const total = addPages ? allPagesTotal : thisPageTotal;

  const openOrCloseValueMore = (ind: number, open?: boolean) => {
    const moreIconsCont = document.getElementById(`moreIconsCont${ind}`);
    const editAmountCont = document.getElementById(`editAmountCont${ind}`);
    const valueTD = document.getElementById(`valueTD${ind}`);
    if (moreIconsCont && valueTD && editAmountCont) {
      if ((moreIconsCont.style.height === '0px' && editAmountCont.style.height === '0px') && open) {
        valueTD.style.zIndex = '2';
        moreIconsCont.style.pointerEvents = 'auto';
        moreIconsCont.style.height = '92px';
        moreIconsCont.style.opacity = '1';
      } else {
        valueTD.style.zIndex = '1';
        moreIconsCont.style.pointerEvents = 'none';
        moreIconsCont.style.height = '0px';
        moreIconsCont.style.opacity = '0';
        editAmountCont.style.pointerEvents = 'none';
        editAmountCont.style.height = '0px';
        editAmountCont.style.opacity = '0';
      }
    }
  };

  const onClickValueMore = (ind: number) => {
    const moreIconsCont = document.getElementById(`moreIconsCont${ind}`);
    const editAmountCont = document.getElementById(`editAmountCont${ind}`);
    if (moreIconsCont && editAmountCont && (moreIconsCont.style.height !== '0px' ||
      editAmountCont.style.height !== '0px')) {
      setHighlightLog(-1);
    } else {
      setHighlightLog(ind);
    }
    for (let i = 0; i <= displayLogs.length; i += 1) {
      openOrCloseValueMore(i, ind === i);
    }
  };

  const onExitValueTD = () => {
    if (!isShowDeleteLog && !isLoading && !isShowEditSave) onClickValueMore(-1);
  };

  const onClickEdit = (ind: number) => {
    openOrCloseValueMore(ind);
    const editAmountCont = document.getElementById(`editAmountCont${ind}`);
    const valueTD = document.getElementById(`valueTD${ind}`);
    if (editAmountCont && valueTD) {
      valueTD.style.zIndex = '2';
      editAmountCont.style.pointerEvents = 'auto';
      editAmountCont.style.height = '153px';
      editAmountCont.style.opacity = '1';
    }
  };

  const onChangeEditing = (value: string) => {
    setEditAmountError('');
    const formatted = value.replaceAll(',', '').replaceAll('₦', '');
    if (formatted[0] === '-') {
      if (
        (formatted.length >= 2 && formatted.slice(1, formatted.length - 1).includes('-')) ||
        (/\D+/.test(formatted.slice(1, formatted.length)))
      ) {
        setEditAmountError('Not a valid entry');
        return;
      }
    } else {
      if (formatted.includes('-') || /\D+/.test(formatted)) {
        setEditAmountError('Not a valid entry');
        return;
      }
    }
    setEditAmount(formatted);
  };

  const onClickSaveEdit = () => {
    setEditAmountError('');
    if (!editAmount) {
      setEditAmountError('Amount is needed');
      return;
    }
    if (editAmount === displayLogs[highlightLog].value) {
      setEditAmountError('No changes made');
      return;
    }
    setIsShowEditSave(true);
  };

  const onSubmitEdit = async () => {
    setIsShowEditSave(false);
    setIsLoading(true);

    const logToEdit = {
      editId: displayLogs[highlightLog].id,
      addLogMessage: `&${displayLogs[highlightLog].type} audit& AUD${displayLogs[highlightLog].id} with
        value &P&${displayLogs[highlightLog].value}&P& changed to &P&${editAmount}&P& by |${username}|`,
      value: editAmount,
    };

    const res = await dispatch(editLog(logToEdit));

    if (res.status === 'success') {
      const newLogsChecked = [...logsChecked.filter((c) => c !== 'Audit change'), 'Audit change'];
      dispatch(setLogsChecked(newLogsChecked));
      const newLog = { ...displayLogs[highlightLog], value: editAmount };
      updateLocalLog(newLog);
      socket.emit('update_log', { roomId: `room${hotelData?.id}`, log: newLog });

      // This waits for DB from editLog() to show it's addLog changes
      await new Promise((resolve) => { setTimeout(resolve, 400); });

      setIsLoading(false);
      setPage(1);

      const editAmountCont = document.getElementById(`editAmountCont${highlightLog}`);
      const valueTD = document.getElementById(`valueTD${highlightLog}`);
      if (editAmountCont && valueTD) {
        valueTD.style.zIndex = '1';
        editAmountCont.style.pointerEvents = 'none';
        editAmountCont.style.height = '0px';
        editAmountCont.style.opacity = '0';
      }

      setHighlightLog(-1);
      toast('Successfully edited audit entry', { type: 'success' });
      return;
    }

    setIsLoading(false);
    setIsShowEditSave(true);
    toast(res.data, { type: 'error' });
  };

  let recentLogTimer: any;

  useEffect(() => {
    if (recentLog) {
      clearTimeout(recentLogTimer);
      const thisIndex = displayLogs.findIndex((l) => l.id.toString() === recentLog.toString());
      document.getElementById(`tdIndex${thisIndex}`)?.scrollIntoView(
        { behavior: 'smooth', block: 'center', inline: 'center' }
      );
      recentLogTimer = setTimeout(() => dispatch(setRecentLog('')), 400);
    } else {
      document.getElementById('table_cont')?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [page, cashIn, cashOut, allItems, checked]);

  useEffect(() => {
    if (!recentLog) {
      setPage(1);
    }
  }, [cashIn, cashOut, allItems, checked]);

  useEffect(() => {
    if ([...logGroups.Hospitality, ...logGroups.Branches].filter((b) => checked.includes(b))
      .length === 0 && checked.length) {
      setAllItems(true);
      setCashIn(true);
      setCashOut(true);
    }
  }, [checked]);

  useEffect(() => {
    setEditAmount(displayLogs[highlightLog]?.value || '');
    setEditAmountError('');
  }, [highlightLog]);

  return (
    <S.Container isCashGroup={logsHaveCashGroup}>
      {isLoading && <Loader />}
      {
        isShowDeleteLog && (
          <Modal
            component={(
              <DeleteLog
                onExit={(refreshPg?: boolean) => {
                  openOrCloseValueMore(highlightLog);
                  setHighlightLog(-1);
                  if (refreshPg) setPage(1);
                  setIsShowDeleteLog(false);
                }}
                id={highlightLog}
                log={displayLogs[highlightLog]}
              />
            )}
            exitOnBgClick={() => setIsShowDeleteLog(false)}
          />
        )
      }
      {
        isShowEditSave && (
          <Modal
            component={(
              <ModalAction
                header="Save amount edit"
                item={`Entry id #${highlightLog + 1}`}
                info={`Change the value of ₦${Number(displayLogs[highlightLog].value).toLocaleString()} to 
                  ₦${Number(editAmount).toLocaleString()}?`}
                onExit={() => setIsShowEditSave(false)}
                onAct={() => onSubmitEdit()}
              />
            )}
            exitOnBgClick={() => setIsShowEditSave(false)}
          />
        )
      }
      <S.AllCont>
        <S.Cont1 id="table_cont">
          <S.Table
            isCashGroup={logsHaveCashGroup}
            itemCount={(auditsMax * page).toString().length}
          >
            <S.THead>
              <S.TR>
                {
                  headers.length ? (
                    <S.TH7
                      isEmpty={displayLogs.length === 0}
                      itemCount={(auditsMax * page).toString().length}
                      isCashGroup={logsHaveCashGroup}
                    >
                      Id
                    </S.TH7>
                  ) : (null)
                }
                {
                  headers.map((hTmp: string) => {
                    let h = JSON.parse(JSON.stringify([...hTmp]));
                    h[0] = h[0].toUpperCase();
                    h = h.join('');
                    if (h === 'Message') {
                      h = 'Details';
                      return (
                        <S.TH2
                          isEmpty={displayLogs.length === 0}
                          key={`logH_${h}`}
                          isCashGroup={logsHaveCashGroup}
                        >
                          {h}
                        </S.TH2>
                      );
                    }
                    if (h === 'Value') {
                      if (!logsHaveCashGroup) {
                        return null;
                      }
                      return (
                        <S.TH4
                          isEmpty={displayLogs.length === 0}
                          key={`logH_${h}`}
                          isCashGroup={logsHaveCashGroup}
                        >
                          {h}
                        </S.TH4>
                      );
                    }
                    if (h === 'Type') {
                      h = 'Audit';
                      return (
                        <S.TH3
                          isEmpty={displayLogs.length === 0}
                          key={`logH_${h}`}
                          isCashGroup={logsHaveCashGroup}
                        >
                          {h}
                        </S.TH3>
                      );
                    }
                    return (
                      <S.TH
                        isEmpty={displayLogs.length === 0}
                        key={`logH_${h}`}
                        isCashGroup={logsHaveCashGroup}
                      >
                        {h}
                      </S.TH>
                    );
                  })
                }
              </S.TR>
            </S.THead>
            <tbody style={{ border: 'none', zIndex: '1' }}>
              {
                displayLogs?.map((log, i) => {
                  const isLast = i === displayLogs.length - 1 &&
                    !(logsHaveCashGroup && addPages && pagesCount > 1);
                  const isNew = new Date().getTime() - new Date(log.updatedAsOf).getTime() <=
                    (2 * 60 * 1000);

                  return (
                    <S.TR2 key={`logTR_${log.type}_${i}`}>
                      <S.TD3
                        isLast={isLast}
                        ind={i}
                        highlighted={highlightLog}
                        isNew={isNew}
                        id={`tdIndex${i}`}
                      >
                        {i + ((page - 1) * auditsMax) + 1}
                      </S.TD3>
                      {
                        headers.map((h) => {
                          let entry: any = (log as any)[h];
                          if (h === 'date') {
                            entry = convertDate(new Date(entry)).replace('. 00:00am', '')
                              .replace('00:00am', '').trim();
                          }
                          entry = formattedEntry(entry);
                          if (h === 'value') {
                            if (!logsHaveCashGroup) {
                              return null;
                            }
                            return (
                              <>
                                <S.TD2
                                  key={`LogTD_${h}_${i}`}
                                  isLast={isLast}
                                  ref={i === 0 ? observedDiv : nonUsedRef}
                                  id={`valueTD${i}`}
                                  onMouseLeave={() => onExitValueTD()}
                                  ind={i}
                                  highlighted={highlightLog}
                                  isNew={isNew}
                                >
                                  {entry[0]}
                                  {
                                    (((log as any)[h] === '0' || Number((log as any)[h])) && Number(log.id)) ? (
                                      <S.MoreCont>
                                        <S.RelMoreCont>
                                          <S.MoreIcon0 onClick={() => onClickValueMore(i)}>
                                            <MoreVert fontSize="small" sx={{ marginLeft: 'auto' }} />
                                          </S.MoreIcon0>
                                          <S.MoreIconsCont id={`moreIconsCont${i}`}>
                                            <S.MoreIconsCont1>
                                              <S.MoreIcon
                                                onClick={() => setIsShowDeleteLog(true)}
                                              >
                                                <Bin fontSize="small" />
                                              </S.MoreIcon>
                                              <S.MoreIcon1 onClick={() => onClickEdit(i)}>
                                                <Edit fontSize="small" />
                                              </S.MoreIcon1>
                                            </S.MoreIconsCont1>
                                          </S.MoreIconsCont>
                                          <S.EditAmountCont
                                            id={`editAmountCont${i}`}
                                          >
                                            <S.EditAmountCont1>
                                              <S.EditThisCont>
                                                <S.HeaderPart>
                                                  <S.Header3>Edit amount</S.Header3>
                                                  <S.IconCont2
                                                    onClick={() => {
                                                      setHighlightLog(-1);
                                                      openOrCloseValueMore(i);
                                                    }}
                                                  >
                                                    <Close fontSize="medium" sx={{ scale: '0.65' }} />
                                                  </S.IconCont2>
                                                </S.HeaderPart>
                                                <S.AmountPart>
                                                  { editAmountError ? (
                                                    <S.Info1 isError>{editAmountError}</S.Info1>
                                                  ) :
                                                    (<S.Info1>Enter new amount</S.Info1>)}
                                                  <S.Input1
                                                    value={
                                                      editAmount ? editAmount === '-' ?
                                                        editAmount : `₦${Number(editAmount).toLocaleString()}` : ''
                                                    }
                                                    amount={Number(editAmount)}
                                                    isError={Boolean(editAmountError)}
                                                    onChange={
                                                      (e) => onChangeEditing(e.target.value)
                                                    }
                                                  />
                                                  <S.Button onClick={() => onClickSaveEdit()}>
                                                    Save
                                                  </S.Button>
                                                </S.AmountPart>
                                              </S.EditThisCont>
                                            </S.EditAmountCont1>
                                          </S.EditAmountCont>
                                        </S.RelMoreCont>
                                      </S.MoreCont>
                                    ) : (null)
                                  }
                                </S.TD2>
                              </>
                            );
                          }
                          if (h === 'message') {
                            return (
                              <S.TD1
                                key={`LogTD_${h}_${i}`}
                                isLast={isLast}
                                ind={i}
                                highlighted={highlightLog}
                                isNew={isNew}
                              >
                                {
                                  entry.map((e: any, j: number) => <span key={`tdE_${h}_${i}_${j}`}>{e}</span>)
                                }
                              </S.TD1>
                            );
                          }
                          return (
                            <S.TD
                              key={`LogTD_${h}_${i}`}
                              fontWeight={h === 'type' ? 500 : undefined}
                              isLast={isLast}
                              ind={i}
                              highlighted={highlightLog}
                              isNew={isNew}
                            >
                              {
                                entry.map((e: any, j: number) => <span key={`tdE_${h}_${i}_${j}`}>{e}</span>)
                              }
                            </S.TD>
                          );
                        })
                      }
                    </S.TR2>
                  );
                })
              }
            </tbody>
          </S.Table>
          {
            (logsHaveCashGroup && addPages && pagesCount > 1) ? (
              <S.Table2 isCashGroup itemCount={(auditsMax * page).toString().length}>
                <S.THead2>
                  <tr>
                    <S.TH9 />
                    <S.TH8>
                      <S.OtherPages>
                        Sum
                        <small style={{ marginLeft: '3px' }}>(this page)</small>
                        <S.OtherPagesTotal id="this_page_t">
                          {
                            thisPageTotal < 0 ? (
                              <S.CashOut isSum>
                                {`₦${thisPageTotal.toLocaleString()}`}
                              </S.CashOut>
                            ) : (
                              <S.CashIn isSum>
                                {`₦${thisPageTotal.toLocaleString()}`}
                              </S.CashIn>
                            )
                          }
                        </S.OtherPagesTotal>
                      </S.OtherPages>
                    </S.TH8>
                  </tr>
                  <tr>
                    <S.TH6 />
                    <S.TH5>
                      <S.OtherPages>
                        Sum
                        <small style={{ marginLeft: '3px' }}>(other pages)</small>
                        <S.OtherPagesTotal id="other_pages_t">
                          {
                            remainderTotal < 0 ? (
                              <S.CashOut isSum>
                                {`₦${remainderTotal.toLocaleString()}`}
                              </S.CashOut>
                            ) : (
                              <S.CashIn isSum>
                                {`₦${remainderTotal.toLocaleString()}`}
                              </S.CashIn>
                            )
                          }
                        </S.OtherPagesTotal>
                      </S.OtherPages>
                    </S.TH5>
                  </tr>
                </S.THead2>
              </S.Table2>
            ) : (null)
          }
        </S.Cont1>
        <S.TFoot isCashGroup={logsHaveCashGroup}>
          {
            logsHaveCashGroup && (
              <>
                <S.TotalFooter>
                  <S.FormatLogs1>
                    <FormatLogs />
                  </S.FormatLogs1>
                  <S.TotalCont>
                    <S.TotalText>Total</S.TotalText>
                    <S.TotalCont1>
                      <S.Total isNegative={(total || 0) < 0}>
                        {`₦${(total || 0).toLocaleString()}`}
                      </S.Total>
                    </S.TotalCont1>
                  </S.TotalCont>
                </S.TotalFooter>
                <S.CashFooter>
                  <S.FormatLogs2>
                    <FormatLogs />
                  </S.FormatLogs2>
                </S.CashFooter>
              </>
            )
          }
          <S.PagesFooter isCashGroup={logsHaveCashGroup}>
            {
              logsHaveCashGroup ? (
                <S.FormatLogs3>
                  <S.FormatSet
                    onClick={() => {
                      if (pagesCount <= 1) return;
                      setAddPages(!addPages);
                      localStorage.setItem('add_pages', addPages ? 'no' : 'yes');
                    }}
                  >
                    <S.ShowCashIn>
                      <span style={{ fontWeight: '400' }}>
                        Add
                        <MediaQuery minWidth={335}>
                          {' '}
                          pages
                        </MediaQuery>
                      </span>
                    </S.ShowCashIn>
                    <S.Select
                      type="checkbox"
                      checked={addPages}
                      disabled={pagesCount <= 1}
                      readOnly
                    />
                  </S.FormatSet>
                </S.FormatLogs3>
              ) : (
                <S.FormatLogs3>
                  <S.FormatSet
                    onClick={() => {
                      setIsUseMetricTime(!isUseMetricTime);
                    }}
                  >
                    <S.All>
                      Time
                      <MediaQuery minWidth={288}>
                        {' '}
                        range
                      </MediaQuery>
                    </S.All>
                    <S.Select
                      type="checkbox"
                      checked={isUseMetricTime}
                      readOnly
                    />
                  </S.FormatSet>
                </S.FormatLogs3>
              )
            }
            <S.PaginationCont>
              <ReactPaginate
                previousLabel={<ArrowLeft fontSize="small" sx={{ height: '22px', width: '22px' }} />}
                nextLabel={<ArrowRight fontSize="small" sx={{ height: '22px', width: '22px' }} />}
                pageClassName="page-item"
                pageLinkClassName="page-item-link"
                previousClassName="page-arrow"
                previousLinkClassName="page-arrow-link"
                nextClassName="page-arrow"
                nextLinkClassName="page-arrow-link"
                breakLabel={<More fontSize="small" sx={{ height: '22px', width: '22px' }} />}
                breakClassName="page-arrow"
                breakLinkClassName="page-arrow-link"
                pageCount={pagesCount}
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                onPageChange={(e) => {
                  setPage(e.selected + 1);
                }}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page - 1}
              />
            </S.PaginationCont>
          </S.PagesFooter>
        </S.TFoot>
        {
          !displayLogs.length ? (
            <S.InfoContainer>
              <S.InfoCont>
                { !logs?.length ? (
                  <S.Info>No activity</S.Info>
                ) : (
                  <>
                    <S.NoResults>No results</S.NoResults>
                    { !checked.length ? (
                      <S.Info>Please select audit to view</S.Info>
                    ) : (null)}
                    { (checked.length && isUseMetricTime) ? (
                      <S.Info>No audits found for the time range</S.Info>
                    ) : (null)}
                    { (checked.length && allItems && !isUseMetricTime && (permission || 0) >= 3) ? (
                      <S.Info>No audits found for your selection</S.Info>
                    ) : (null)}
                    { (permission || 0) < 3 ? (
                      <S.Info>No audits by you</S.Info>
                    ) : (null)}
                    { (checked.length && !allItems && !isUseMetricTime && cashIn && cashOut) ? (
                      <S.Info>No cash flow found for your selection</S.Info>
                    ) : (null)}
                    { (checked.length && !allItems && !isUseMetricTime && cashIn && !cashOut) ? (
                      <S.Info>No cash-inflow found for your selection</S.Info>
                    ) : (null)}
                    { (checked.length && !allItems && !isUseMetricTime && !cashIn && cashOut) ? (
                      <S.Info>No cash-outflow found for your selection</S.Info>
                    ) : (null)}
                  </>
                )}
              </S.InfoCont>
            </S.InfoContainer>
          ) : (null)
        }
      </S.AllCont>
    </S.Container>
  );
};

export default LogTable;
