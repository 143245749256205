import styled from 'styled-components';
import {
  topBarColor2, topBarHeight
} from 'pages/styles';
import { cashColor } from 'pages/Logs/components/LogTable.styled';
import Color from 'color';

export const mobileRes = '669px';
export const headerRes1 = '542px';

export const Container = styled.div`
  background: ${topBarColor2};
  height: ${topBarHeight};
  width: 100vw;
  display: block;
  padding: 15px;
  position: relative;
  z-index: 1;
  margin: 0;
  @media(max-width: ${headerRes1}) {
    padding: 15px 11px;
  }
`;

export const LinkCont = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: #c5daed;
  &:hover {
    cursor: pointer;
    color: white;
  }
`;

export const Link = styled.div`
  margin-left: 35px;
  font-size: 13px;
  font-weight: 500;
  margin-top: 1px;
`;

export const LinkIconCont = styled.div`
  width: 8px;
  height: 6px;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Menu = styled.div`
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const NamePart = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 10px;
  gap: 6px;
`;

export const MenuIcon = styled.div`
  color: white;
  width: 20px;
  height: 20px;
  display: none;
  margin-left: -2px;
  margin-top: -3px;
  cursor: pointer;
  &:hover {
    color: lightgrey;
  }
  @media(max-width: ${mobileRes}) {
    display: block;
  }
`;

export const HotelName = styled.div`
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    color: lightgrey;
  }
  @media(max-width: 400px) {
    font-size: 16px;
  }
  @media(max-width: 293px) {
    font-size: 14px;
  }
`;

export const HotelDesc = styled.div`
  font-size: 13px;
  font-weight: 400;
  margin-left: auto;
`;

export const LodgerBeeCont = styled.div<{ isWeb: boolean, isMobile: boolean }>`
  display: flex;
  font-size: 16px;
  font-family: Poppins;
  color: ${({ isMobile }) => !isMobile ? 'white' : topBarColor2};
  margin-top: 2px;
  @media(max-width: ${headerRes1}) {
    display: ${({ isWeb }) => isWeb && 'none'}; 
  }
`;

export const Lodger = styled.div`
  font-weight: 500;
`;

export const Bee = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

export const BeeCont = styled.span`
  overflow: hidden;
  width: 30px;
  height: 30px;
  color: ${Color(cashColor).lighten(0.6).toString()};
  position: relative;
  transform: translateY(7px);
  margin-left: -11px;
`;

export const NumberSect = styled.div`
  display: flex;
  flex-direction: column;
  gap: -10px;
  margin-left: auto;
  height: max-content;
`;

export const CallUs = styled.div`
  font-size: 12px;
  font-weight: 500;
  height: 13px;
  text-align: right;
`;

export const NumberCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -2px;
  margin-top: 1px;
  height: 26px;
  border-radius: 4px;
  color: #e6eaed;
  padding-right: 1px;
  padding: 0px 7px;
  padding-left: 5px;
  gap: 2px;
  &:hover {
    cursor: pointer;
    background: #0067b5;
  }
`;

export const Number = styled.div`
  font-size: 14.5px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-top: -1px;
  @media(max-width: 293px) {
    font-size: 13px;
  }
`;

export const UserProfile = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: -1px;
`;

export const IconCont1 = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: relative;
  background: white;
  margin-top: 1px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  &:hover {
    background: #ababab;
  }
`;

export const IconCont = styled.div`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  bottom: -1px;
  color: ${topBarColor2};
  background-color: transparent;
`;

export const UserCont = styled.div`
  font-size: 13px;
  text-align: right;
  margin-right: 6px;
  margin-top: 0px;
`;

export const User = styled.div`
  font-weight: 500;
  height: 14px;
  margin-left: auto;
`;

export const Role = styled.div`
  font-size: 11px;
  margin-top: 2px;
  color: lightblue;
`;
