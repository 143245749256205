import React, { useState } from 'react';
import * as S from './DeleteStaffModal.styled';
import Loader from 'pages/components/Loader/Loader';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { User } from '../SetEmailDetails';
import { deleteStaff } from 'pages/Settings/redux';
import { selectHotelData, selectSocket } from 'pages/redux';

const DeleteStaffModal = function DeleteStaffModal(
  {
    onExit, users, selectedUser, setUsers,
  }:
  { onExit: Function, users: User[], selectedUser: User, setUsers: Function },
) {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const socket = useAppSelector(selectSocket);
  const hotelData = useAppSelector(selectHotelData);

  return (
    <>
      { loading ? <Loader isSticky />
        : (
          <S.Container>
            <EscapeButton onClick={() => onExit()} />
            <S.MainLabel>Delete Staff access</S.MainLabel>
            <S.Info>
              <strong>{selectedUser.username}</strong>
            </S.Info>
            <S.Info>
              {selectedUser.email}
            </S.Info>
            <S.Info1>
              Are you sure you want to delete this staff access?
            </S.Info1>
            <S.Button1>
              <div style={{ color: '#1685ec' }}>
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ borderWidth: '1px' }}
                  onClick={() => onExit()}
                >
                  Back
                </Button>
              </div>
              <Button
                variant="outlined"
                color="inherit"
                sx={{ borderWidth: '1px' }}
                onClick={async () => {
                  setLoading(true);
                  const res = await dispatch(deleteStaff(selectedUser.email));
                  setLoading(false);
                  if (res.status === 'success') {
                    socket.emit('revoke_staff', { roomId: `room${hotelData?.id}`, username: selectedUser.username });
                    const thisUser = users.find((u) => u.email === selectedUser.email);
                    if (thisUser) {
                      const id = users.indexOf(thisUser);
                      const newUsers = [...users];
                      newUsers.splice(id, 1);
                      setUsers(newUsers);
                      toast(`Successfully deleted staff member (${selectedUser.username}).`, { type: 'success' });
                      onExit();
                    } else {
                      toast('An error occured', { type: 'error' });
                    }
                    return;
                  }
                  toast(res.data, { type: 'error' });
                }}
              >
                Yes, Delete
              </Button>
            </S.Button1>
          </S.Container>
        )}
    </>
  );
};

export default DeleteStaffModal;
