import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { MetricsKey } from './hooks';

export type MoveType = 'month' | 'week' | 'day';
export type MetricsFlowType = '+' | '-' | 'both' | 'count';

interface MetricsState {
  jointMove: string,
  moveType: MoveType,
  metricChecked: MetricsKey | '',
  metricsFlowType: MetricsFlowType,
}

const initialState: MetricsState = {
  jointMove: localStorage.getItem('joint_move') || '',
  moveType: localStorage.getItem('move_type') as MoveType || 'day',
  metricChecked: 'All audits',
  metricsFlowType: 'both',
};

export const counterSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {
    setJointMove: (state, action: PayloadAction<string>) => {
      state.jointMove = action.payload;
      localStorage.setItem('joint_move', action.payload);
    },
    setMoveType: (state, action: PayloadAction<MoveType>) => {
      state.moveType = action.payload;
      localStorage.setItem('move_type', action.payload);
    },
    setMetricChecked: (state, action: PayloadAction<MetricsKey | ''>) => {
      state.metricChecked = action.payload;
    },
    setMetricsFlowType: (state, action: PayloadAction<MetricsFlowType>) => {
      state.metricsFlowType = action.payload;
    }
  },
});

export const {
  setJointMove,
  setMoveType,
  setMetricChecked,
  setMetricsFlowType
} = counterSlice.actions;

export const selectJointMove = (state: RootState) => state.metrics.jointMove;
export const selectMoveType = (state: RootState) => state.metrics.moveType;
export const selectMetricChecked = (state: RootState) => state.metrics.metricChecked;
export const selectMetricsFlowType = (state: RootState) => state.metrics.metricsFlowType;

export default counterSlice.reducer;
