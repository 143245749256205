import Color from 'color';
import { topBarColor, topBarColor2 } from 'pages/styles';
import styled from 'styled-components';

const cColor = Color('#56d651').lighten(0).toString();
export const CartBarStyle = styled.div`
  display: flex;
  align-items: center;
  padding: 1px 13px;
  padding-bottom: 3px;
  padding-left: 11px;
  font-size: 13px;
  color: ${Color(cColor).darken(0.7).toString()};
  font-weight: 500;
`;

export const CartBar = styled(CartBarStyle)<{ isInfoBar: boolean }>`
  color: ${({ isInfoBar }) => isInfoBar ? Color(topBarColor2).lighten(0.4).toString()
    : Color(topBarColor).lighten(0.75).toString()};
  border: 0.5px solid ${({ isInfoBar }) => isInfoBar ? Color(topBarColor2).lighten(0.4).toString() :
    Color(topBarColor).lighten(0.6).toString()};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right: none;
  padding-right: 5px;
  padding-left: 9px;
  gap: 1px;
  z-index: 1;
  margin-left: 15px;
`;

export const CartRoomBar = styled(CartBarStyle)`
  border: 1px solid ${Color(cColor).lighten(0.05).toString()};
  background: ${Color(cColor).lighten(0.05).toString()};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: -1px;
  z-index: 2;
`;

export const Container = styled.div<{ isInfoBar: boolean }>`
  display: flex;
  margin-left: auto;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    ${CartBar} {
      background: ${({ isInfoBar }) => isInfoBar ? Color(topBarColor).lighten(0.7).toString()
    : Color(topBarColor2).lighten(0.65).toString()};
    }
    ${CartRoomBar} {
      background: ${({ isInfoBar }) => isInfoBar ? Color(cColor).darken(0.25).toString()
    : Color(cColor).lighten(0.45).toString()};
      border-color: ${({ isInfoBar }) => isInfoBar ? Color(cColor).darken(0.25).toString()
    : Color(cColor).lighten(0.45).toString()};
    }
  }
`;
