import Color from 'color';
import { orange } from 'pages/App/components/Rooms.styled';
import { lFColor } from 'pages/components/LFHeader/LFHeader.styled';
import {
  borderColor,
  logRes1,
  mobileRes2,
  topBarColor2,
} from 'pages/styles';
import styled from 'styled-components';

export const scrollBarRes = '1150px';

export const AuditsCont = styled.div<{ isWeb?: boolean }>`
  width: max-content;
  background: white;
  flex: 0 1;
  display: flex;
  flex-direction: column;
  @media(max-width: ${logRes1}) {
    position: ${({ isWeb }) => isWeb && 'fixed'};
    left: ${({ isWeb }) => isWeb && '-100%'};
  }
`;

const padding = '50px';
const padding2 = '32px';
export const Header = styled.div<{ isWeb?: boolean }>`
  width: max-content;
  font-weight: 700;
  font-size: 15px;
  margin-top: 35px;
  padding-bottom: 15px;
  padding-right: calc(${padding} + 5px);
  color: ${topBarColor2};
  text-align: right;
  margin-left: auto;
  @media(max-width: ${scrollBarRes}) {
    padding-right: ${padding};
  }
  @media(max-width: ${logRes1}) {
    display: flex;
    align-items: flex-end;
    padding: 12px 40px;
    padding-top: 25px;
    padding-left: 17px;
  }
  @media(max-width: ${mobileRes2}) {
    padding-right: calc(${padding2});
  }
`;

export const Audits = styled.div`
  flex: 1;
  padding: 0px ${padding};
  padding-left: calc(${padding} + 3px);
  padding-top: 42px;
  color: ${borderColor};
  overflow-y: auto;
  position: relative;
  margin-bottom: 30px;
  border-top: 1px solid ${Color('lightgrey').lighten(0.03).toString()};
  border-bottom: 1px solid ${Color('lightgrey').lighten(0.03).toString()};
  @media(max-width: ${mobileRes2}) {
    padding: 0px ${padding2};
  }
`;

export const AuditCont = styled.div`
  width: 100%;
  padding-bottom: 34px;
`;

export const AuditGroup1 = styled.div`
  width: max-content;
  margin-left: auto;
  font-weight: 600;
  font-size: 14px;
  color: ${topBarColor2};
  padding-bottom: 10px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  &:hover {
    color: ${Color('#1685ec').darken(0.2).toString()};
  }
  @media(max-width: ${mobileRes2}) {
    font-size: 13px;
  }
`;

export const AuditGroup0 = styled(AuditGroup1)`
  padding: 9px 15px;
  color: white;
  font-weight: 700;
  background: ${Color(lFColor).lighten(0.2).toString()};
  border-radius: 3px;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 12px;
  &:hover {
    color: white;
    background: ${Color(orange).lighten(0.2).toString()};
  }
`;

export const AuditGroup = styled(AuditGroup1)`
  padding-bottom: 13px;
`;

export const Select = styled.input`
  height: 19px;
  width: 19px;
  margin: 0;
  margin-left: 12px;
  pointer-events: none;
`;

export const Select2 = styled(Select)`
  height: 15.5px;
  width: 15.5px;
`;

export const AuditName = styled.div`
  width: max-content;
  margin-left: auto;
  font-size: 13px;
  color: #525252;
  padding: 15px 0 13px 0;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  &:hover {
    color: ${Color('#1685ec').darken(0.2).toString()};
  }
  @media(max-width: ${mobileRes2}) {
    font-size: 12px;
  }
`;

export const EsacpePart = styled.div`
  display: none;
  width: 100%;
  height: 0px;
  overflow: visible;
  position: absolute;
  top: 0;
  right: 0;
  @media(max-width: ${logRes1}) {
    display: block;
  }
`;
