import { callEndpoint } from 'redux/global';
import { IResponse } from 'global/types';
import { Details } from './components/SetPhoneNumbers';
import { User } from './components/SetEmailDetails';

export const getInfo = () => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'info',
      method: 'GET',
    }),
  );
  return res;
};

export const saveNumbers = (body: Details[]) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'savenumbers',
      body: { numbers: body },
      method: 'PATCH',
    }),
  );
  return res;
};

export const setEmailRec =
(emailRec: string | null) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'setemailreceiver',
      body: { emailRec },
      method: 'PATCH',
    }),
  );
  return res;
};

type User2 = {
  path: string
} & User;

export const addStaff = (body: User2) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'addstaff',
      body,
      method: 'POST',
    }),
  );
  return res;
};

export const editStaff = (body: User) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'editstaff',
      body,
      method: 'PATCH',
    }),
  );
  return res;
};

export const deleteStaff = (email: string) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'deletestaff',
      body: { email },
      method: 'DELETE',
    }),
  );
  return res;
};

export const saveDisplayNumber =
(displayNumber: string) => async (dispatch: Function): Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'savedisplaynumber',
      body: { displayNumber },
      method: 'PATCH',
    }),
  );
  return res;
};
