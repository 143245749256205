import Color from 'color';
import { orange } from 'pages/App/components/Rooms.styled';
import { lFColor, lFColor0 } from 'pages/components/LFHeader/LFHeader.styled';
import { mobileRes2, topBarColor } from 'pages/styles';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div<{ isShow: boolean }>`
  background: white;
  width: calc(100% - 1px);
  height: calc(100% + 1.5px);
  display: ${({ isShow }) => isShow ? 'block' : 'none'};
  color: #525252;
  padding: 12px;
  margin-left: -0.5px;
  margin-top: -0.5px;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  position: relative;
`;

export const HeaderPart = styled.div`
  width: 100%;
  display: flex;
`;

export const Header = styled.div`
  font-size: 27px;
  font-weight: 700;
  margin-top: -6px;
  display: flex;
  align-items: flex-end;
`;

export const InfoPart = styled.div`
  display: flex;
  gap: 7px;
  color: ${topBarColor};
`;

export const Info = styled.div`
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 15px;
  background: white;
  display: flex;
  width: max-content;
  cursor: pointer;
  &:hover {
    color: ${orange};
  }
`;

export const Hyphen = styled.div`
  transform: translateY(-2px);
  font-size: 13px;
`;

export const AbsStyle = styled.div`
  position: absolute;
  bottom: 13px;
  left: 13px;
  width: max-content;
  height: max-content;
`;

const actionContH = '50px';

export const ActionsCont = styled(AbsStyle)`
  height: ${actionContH};
  width: max-content;
  display: flex;
  gap: 12px;
`;

const actionAnimation = () => keyframes`
  0% {
    scale: 0;
  }
  100% {
    scale: 1;
  }
`;

export const Actions = styled.div`
  height: 100%;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid ${lFColor0};
  color: ${lFColor0};
  font-size: 11px;
  animation:
    ${actionAnimation()}
    ease-out
    37.5ms;
  -webkit-animation-fill-mode: forwards;
  cursor: pointer;
  &:hover{
    color: ${orange};
    border-color: ${orange};
  }
`;

export const Button = styled(AbsStyle)`
  width: max-content;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-end;
  padding: 9px 15px;
  color: white;
  font-weight: 700;
  background: ${Color(lFColor).lighten(0.2).toString()};
  border-radius: 3px;
  font-size: 13px;
  margin-top: 5px;
  cursor: pointer;
  transition: all ease-in 57ms;
  &:hover {
    color: white;
    background: ${Color(orange).lighten(0.2).toString()};
  }
  @media(max-width: ${mobileRes2}) {
    font-size: 13px;
  }
`;

export const CollapseCont = styled.div`
  margin-left: 5px;
  overflow: hidden;
  visibility: hidden;
  width: 0px;
  display: flex;
  transition: all ease-out 200ms;
`;

export const IconCont = styled.div`
  transform: translateY(-1px);
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${orange};
  }
`;

export const IconCont0 = styled(IconCont)`
  transform: translate(5px, -2px);
`;

export const IconCont1 = styled(IconCont)`
  margin-left: 0px;
  height: 25px;
  &:hover {
    color: red;
  }
`;

export const IconCont2 = styled(IconCont)`
  margin-left: auto;
  transform: translate(5px, -5px);
  &:hover {
    color: red;
  }
`;
