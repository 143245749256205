import React, { useEffect, useState } from 'react';
import * as S from './Logs.styled';
import { useLogs, useLogsSelector, useWindowSize } from './hooks';
import Loader from 'pages/components/Loader/Loader';
import LogTable from './components/LogTable';
import TmpPanel from 'pages/components/TmpPanel/TmpPanel';
import QuickOptions from './components/QuickOptions';
import { useAppSelector } from 'redux/hooks';
import { selectLogsChecked } from './redux';
import { selectPermission } from 'redux/global';

export const logTypes = ['Desk reservation', 'Reservation cancelled', 'Room added', 'Staff logged in',
  'Staff logout', 'Online visitor', 'Settings change', 'Online reservation', 'Room change', 'Reservation change',
  'Staff added', 'Staff removed', 'Staff change', 'Price change', 'Room deleted', 'Audit deleted',
  'Audit change', 'Walk in'] as const;
export type LogType = typeof logTypes[number];

export const logKeys = ['Hospitality', 'Branches', 'Actions', 'Systems', 'Show all'];
export type LogKey = typeof logKeys[number];

export type QuickLinks = {
  name: string,
  func: Function
}

export type LogGroups = {
  Hospitality: ('Desk reservation' | 'Online reservation' | 'Reservation cancelled' | 'Reservation change')[],
  Branches: string[],
  Actions: ('Staff logged in' | 'Walk in' | 'Online visitor')[],
  Systems: (
    'Price change' | 'Staff added' | 'Staff change' | 'Staff removed' | 'Settings change' | 'Room added' | 'Room change' |
    'Room deleted' | 'Audit change' | 'Audit deleted'
  )[]
}

export const logGroupsRaw: LogGroups = {
  Hospitality: ['Desk reservation', 'Online reservation', 'Reservation cancelled', 'Reservation change'],
  Branches: [],
  Actions: ['Staff logged in', 'Walk in', 'Online visitor'],
  Systems: [
    'Price change', 'Staff added', 'Staff change', 'Staff removed', 'Settings change', 'Room added', 'Room change',
    'Room deleted', 'Audit change', 'Audit deleted'
  ]
};

const Logs = () => {
  const { isLoadingLogs } = useLogs();

  const triggerAllItems0 = () => {
    setTriggerAllItems(!triggerAllItems);
  };

  const triggerAllItems1 = (key: LogKey, logType: string) => {
    if (key !== 'Hospitality' && key !== 'Branches') {
      setTriggerAllItems(!triggerAllItems);
      return;
    }
    if (logType === 'Online reservation' || logType === 'Desk reservation') {
      setTriggerCashIn(!triggerCashIn);
      return;
    }
    if (logType === 'Reservation change') {
      setTriggerCashIn(!triggerCashIn);
      setTriggerCashOut(!triggerCashOut);
      return;
    }
    if (logType === 'Reservation cancelled') {
      setTriggerCashOut(!triggerCashOut);
    }
  };

  const {
    AuditSelector, onClickAuditKey, onClickAudit, onClickShowAll
  } = useLogsSelector({
    triggerAllItems0,
    triggerAllItems1,
  });
  const checked = useAppSelector(selectLogsChecked);
  const permission = useAppSelector(selectPermission);
  const [triggerAllItems, setTriggerAllItems] = useState<boolean>(false);
  const [triggerCashIn, setTriggerCashIn] = useState<boolean>(false);
  const [triggerCashOut, setTriggerCashOut] = useState<boolean>(false);

  const origQuickLinks: QuickLinks[] = [
    {
      name: 'Show all',
      func: (check: QuickLinks['name'][]) => {
        onClickShowAll(check);
        triggerAllItems0();
      }
    },
    {
      name: 'Hospitality',
      func: (check: QuickLinks['name'][]) => {
        onClickAuditKey('Hospitality', check);
        triggerAllItems0();
      }
    },
    {
      name: 'Branches',
      func: (check: QuickLinks['name'][]) => {
        onClickAuditKey('Branches', check);
        triggerAllItems0();
      }
    },
    {
      name: 'Actions',
      func: (check: QuickLinks['name'][]) => {
        onClickAuditKey('Actions', check);
        triggerAllItems0();
      }
    },
    {
      name: 'Desk reservation',
      func: (check: QuickLinks['name'][]) => {
        onClickAudit('Hospitality', 'Desk reservation', check);
        triggerAllItems1('Hospitality', 'Desk reservation');
      }
    },
  ];

  const startQLinks = [...origQuickLinks];
  startQLinks.splice(1, 4);
  const [quickLinks, setQuickLinks] = useState<QuickLinks[]>(startQLinks);
  const { width: windowW } = useWindowSize();

  useEffect(() => {
    const qLinks = [...origQuickLinks];
    if (windowW) {
      if (windowW <= 403) {
        qLinks.splice(1, 4);
        setQuickLinks(qLinks);
        return;
      }
      if (windowW < 577) {
        qLinks.splice(2, 3);
        setQuickLinks(qLinks);
        return;
      }
      if (windowW < 768) {
        qLinks.splice(3, 2);
        setQuickLinks(qLinks);
        return;
      }
      if (windowW < 916) {
        qLinks.splice(4, 1);
        setQuickLinks(qLinks);
      } else {
        setQuickLinks(qLinks);
      }
    }
  }, [windowW]);

  return (
    <S.Container>
      {isLoadingLogs && <Loader />}
      {
        (permission || 0) >= 3 ? (
          <>
            <TmpPanel component={<AuditSelector />} id="tmp_panel0" />
            <AuditSelector isWeb />
            <S.AuditsPanelMobile>
              <S.AuditsText>Audits</S.AuditsText>
              <QuickOptions
                quickLinks={quickLinks}
                checked={checked}
                buttonLabel="Select Audit"
              />
            </S.AuditsPanelMobile>
          </>
        ) : (null)
      }
      <LogTable
        checked={checked}
        triggerAllItems={triggerAllItems}
        triggerCashIn={triggerCashIn}
        triggerCashOut={triggerCashOut}
      />
    </S.Container>
  );
};

export default Logs;
