import React, { useState } from 'react';
import * as S from './SetEmailDetails.styled';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import Loader from 'pages/components/Loader/Loader';
import { isValidatedEmail } from 'global/utils';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { selectPermission } from 'redux/global';
import Modal from 'pages/components/Modal/Modal';
import EditStaffModal from './modals/EditStaffModal';
import { selectUserEmail } from 'pages/App/redux';
import DeleteStaffModal from './modals/DeleteStaffModal';
import { addStaff } from '../redux';
import { toast } from 'react-toastify';

export type User = {
  email: string,
  username: string,
  permission: string,
}

export const rolesMain1 = ['Worker', 'Front desk', 'Front desk 2', 'Sub Manager', 'Manager', 'Owner', 'Tech team'] as const;
export const rolesMain = ['Worker', 'Front desk', 'Front desk 2', 'Sub Manager', 'Manager', 'Owner'] as const;
export type Roles = typeof rolesMain1[number];

const SetStaffMembers = function SetStaffMembers(
  { users, setUsers }:
  { users: User[], setUsers: Function },
) {
  const permission = useAppSelector(selectPermission);
  const roles: Roles[] = [...rolesMain];
  if (Number(permission) === 6) roles.push('Tech team');
  const [userType, setUserType] = useState<Roles>();
  const [userTypeError, setUserTypeError] = useState<string>();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [usernameError, setUsernameError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>(users[0]);
  const [isShowEditStaffModal, setIsShowEditStaffModal] = useState<boolean>(false);
  const [isShowDeleteStaffModal, setIsShowDeleteStaffModal] = useState<boolean>(false);
  const savedEmail = useAppSelector(selectUserEmail);
  const dispatch = useAppDispatch();

  const icons = [
    {
      icon: <Edit fontSize="medium" />,
      color: '#525252',
      text: 'Edit',
      onClick: () => setIsShowEditStaffModal(true),
    },
    {
      icon: <Delete fontSize="medium" />,
      color: '#eb364b',
      text: 'Delete',
      onClick: () => setIsShowDeleteStaffModal(true),
    },
  ];

  const handleAdd = async () => {
    setUserTypeError('');
    setEmailError('');
    setUsernameError('');
    if (!userType) {
      setUserTypeError('User role must be selected.');
      return;
    }
    if (!isValidatedEmail(email)) {
      setEmailError('Email is invalid!');
      return;
    }
    if (!username) {
      setUsernameError('Username is required');
      return;
    }
    const emailExists = users.find((u) => u.email.toLowerCase() === email.toLowerCase());
    if (emailExists) {
      setEmailError('User with this email already exists in staff below.');
      return;
    }
    const userExists = users.find((u) => u.username.toLowerCase() === username.toLowerCase());
    if (userExists) {
      setUsernameError('This username already exists in staff below.');
      return;
    }
    if (/^[&%$^]+$/.test(username)) {
      setUsernameError("These symbols are not allowed '& % $ ^'.");
      return;
    }
    if (username.length <= 2) {
      setUsernameError('This username is too short');
      return;
    }
    setLoading(true);
    const permissionHere = roles.indexOf(userType).toString();
    const path = `${window.location.origin}/register`;
    const apiBody = {
      username,
      email: email.toLowerCase(),
      permission: permissionHere,
      path,
    };
    const res = await dispatch(addStaff(apiBody));
    setLoading(false);
    if (res.status === 'success') {
      const newUsers = [...users, apiBody];
      setUsers(newUsers);
      setUsername('');
      setEmail('');
      toast(`Successfully added staff. An email has been sent to the staff on how to
        register his/her account.`, { type: 'success' });
      return;
    }
    toast(res.data, { type: 'error' });
  };

  return (
    <S.Container>
      { loading && <Loader /> }
      {
        isShowEditStaffModal && (
          <Modal component={(
            <EditStaffModal
              onExit={() => setIsShowEditStaffModal(false)}
              users={users}
              selectedUser={selectedUser}
              setUsers={setUsers}
            />
            )}
          />
        )
      }
      {
        isShowDeleteStaffModal && (
          <Modal component={(
            <DeleteStaffModal
              onExit={() => setIsShowDeleteStaffModal(false)}
              users={users}
              selectedUser={selectedUser}
              setUsers={setUsers}
            />
            )}
          />
        )
      }
      <S.AddContainer>
        <S.DetailsLabel>
          Grant new staff access
        </S.DetailsLabel>
        <S.Select
          onChange={(e) => {
            setUserTypeError('');
            setUserType(e.target.value as Roles);
          }}
        >
          <option disabled selected>Select role</option>
          {
            roles.map((userHere) => (
              <option key={`user_${userHere}`}>{userHere}</option>
            ))
          }
        </S.Select>
        { userTypeError && <S.Error>{userTypeError}</S.Error>}
        <S.Input
          placeholder="Email"
          value={email}
          error={Boolean(emailError)}
          onChange={(e) => {
            setEmailError('');
            setEmail(e.target.value);
            if (e.target.value !== '' && !isValidatedEmail(e.target.value)) {
              setEmailError('Email is invalid');
            } else {
              setEmailError('');
            }
          }}
        />
        { emailError && <S.Error>{emailError}</S.Error>}
        <S.Input
          placeholder="Username"
          value={username}
          error={Boolean(usernameError)}
          onChange={(e) => {
            setUsernameError('');
            setUsername(e.target.value);
          }}
        />
        { usernameError && <S.Error>{usernameError}</S.Error>}
        <div style={{ color: '#1685ec' }}>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ borderWidth: '1px', marginTop: '28px' }}
            onClick={() => handleAdd()}
          >
            Add staff
          </Button>
        </div>
      </S.AddContainer>
      <S.Details>
        <S.DetailsLabel1>
          Staff with access
        </S.DetailsLabel1>
        <S.AddedCont1>
          {
            [...users].reverse().map((u, i) => {
              return (
                <S.AddedCont key={`detail_${u.username}_${i}`} noTopBorder={i > 0}>
                  <S.Id>{i + 1}</S.Id>
                  <S.Labels>
                    <S.AddedLabel>{u.username}</S.AddedLabel>
                    <S.AddedLabel1>{u.email}</S.AddedLabel1>
                    <S.AddedLabel1>{[...roles, 'Tect Team'][Number(u.permission)]}</S.AddedLabel1>
                  </S.Labels>
                  <S.ButtonCont>
                    {
                      icons.map((icon) => {
                        const isDisabled = Number(u.permission) > (permission || 0)
                          || (u.email === savedEmail && icon.text !== 'Edit');
                        return (
                          <S.IconCont
                            color={icon.color}
                            onClick={() => {
                              if (isDisabled) return;
                              setSelectedUser(u);
                              icon.onClick();
                            }}
                            disabled={isDisabled}
                            key={`opIcon_${icon.text}`}
                          >
                            <S.Icon>{icon.icon}</S.Icon>
                            <S.Text>{icon.text}</S.Text>
                          </S.IconCont>
                        );
                      })
                    }
                  </S.ButtonCont>
                </S.AddedCont>
              );
            })
          }
        </S.AddedCont1>
      </S.Details>
    </S.Container>
  );
};

export default SetStaffMembers;
