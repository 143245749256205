import { faBuilding, faEnvelope, faPhoneFlip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as S from './Location.styled';
import { toast } from 'react-toastify';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useAppSelector } from 'redux/hooks';
import { selectHotelData } from 'pages/redux';

const Location = function Location() {
  const hotelData = useAppSelector(selectHotelData);

  return (
    <S.MainCont>
      <S.Container>
        <S.LogoCont1>
          <S.LogoCont>
            <S.Logo><FontAwesomeIcon icon={faBuilding as IconProp} size="2x" /></S.Logo>
          </S.LogoCont>
        </S.LogoCont1>
        <S.Address>
          {hotelData?.address}
        </S.Address>
      </S.Container>
      {
        hotelData?.phoneNumber && (
        <S.Container2>
          <S.LogoCont1>
            <S.LogoCont>
              <S.Logo2><FontAwesomeIcon icon={faPhoneFlip as IconProp} size="2x" /></S.Logo2>
            </S.LogoCont>
          </S.LogoCont1>
          <S.Number
            onClick={() => {
              navigator.clipboard.writeText(hotelData.phoneNumber);
              toast('Copied', { type: 'success' });
            }}
          >
            {hotelData.phoneNumber}
          </S.Number>
        </S.Container2>
        )
      }
      {
        hotelData?.email && (
          <S.Container2>
            <S.LogoCont1>
              <S.LogoCont>
                <S.Logo2><FontAwesomeIcon icon={faEnvelope as IconProp} size="2x" /></S.Logo2>
              </S.LogoCont>
            </S.LogoCont1>
            <S.Number onClick={() => window.open(`mailto:${hotelData.email}`)}>
              {hotelData.email}
            </S.Number>
          </S.Container2>
        )
      }
    </S.MainCont>
  );
};

export default Location;
