import React, { useState } from 'react';
import * as S from './DeleteLog.styled';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import { Log, useLogs } from '../hooks';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { deleteLog, selectLogsChecked, setLogsChecked } from '../redux';
import Loader from 'pages/components/Loader/Loader';
import { toast } from 'react-toastify';
import { selectHotelData, selectSocket } from 'pages/redux';

const DeleteLog = ({
  onExit,
  log,
  id
} : {
  onExit: Function,
  log: Log,
  id: string | number
}) => {
  const displayLog = { ...log };
  const logsChecked = useAppSelector(selectLogsChecked);
  const socket = useAppSelector(selectSocket);
  const hotelData = useAppSelector(selectHotelData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { deleteLocalLog } = useLogs();

  const dispatch = useAppDispatch();

  const onDeleteLog = async () => {
    const logToDelete = {
      dId: displayLog.id.toString(),
      type: displayLog.type,
      value: displayLog.value
    };
    setIsLoading(true);

    const res = await dispatch(deleteLog(logToDelete));

    if (res.status === 'success') {
      deleteLocalLog(displayLog.id);
      socket.emit('delete_log', { roomId: `room${hotelData?.id}`, logId: displayLog.id });
      const newChecked = [...logsChecked.filter((c: string) => c !== 'Audit deleted'), 'Audit deleted'];
      dispatch(setLogsChecked(newChecked));
      setIsLoading(true);
      setIsLoading(true);

      // This waits for DB from deleteLog() to show it's addLog changes
      await new Promise((resolve) => { setTimeout(resolve, 400); });

      setIsLoading(false);
      toast('Successfully deleted audit', { type: 'success' });
      onExit(true);
      return;
    }

    setIsLoading(false);
    toast(res.data, { type: 'error' });
  };

  return (
    <>
      {
        isLoading ? <Loader isSticky /> : (
          <S.Container>
            <EscapeButton onClick={() => onExit()} />
            <S.Header>Delete audit entry</S.Header>
            <S.Entry>{`₦${Number(displayLog.value).toLocaleString()}`}</S.Entry>
            <S.Label>
              {'Are you sure you want to delete this entry with Id '}
              <strong>{`#${(Number(id) || 0) + 1}`}</strong>
              ?
            </S.Label>
            <S.Note>
              <strong>{'NOTE '}</strong>
              This is irreversible
            </S.Note>
            <S.ButtonsCont>
              <S.Button1 onClick={() => onExit()}>
                Cancel
              </S.Button1>
              <S.Button0 onClick={() => onDeleteLog()}>
                DELETE
              </S.Button0>
            </S.ButtonsCont>
          </S.Container>
        )
      }
    </>
  );
};

export default DeleteLog;
