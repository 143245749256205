import React, { useState } from 'react';
import Logo from './Logo/Logo';
import * as S from './Footer.styled';
import Location from './Location/Location';
import Social from './Social/Social';
import { useHistory, useLocation } from 'react-router-dom';
import SignInModal from '../../Homepage/modals/SignInModal';
import Modal from 'pages/components/Modal/Modal';
import StaffWarningModal from '../../Homepage/modals/StaffWarningModal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsScrollScreen, setIsScrollScreen } from 'pages/App/redux';
import LoggedInModal from 'pages/components/LFHeader/modals/LoggedInModal';

function Footer() {
  const history = useHistory();
  const [isShowSignIn, setIsShowSignIn] = useState<boolean>();
  const [isShowStaffModal, setIsShowStaffModal] = useState<boolean>(false);
  const [isShowLoggedIn, setIsShowLoggedIn] = useState<boolean>();
  const isScrollAppScreen = useAppSelector(selectIsScrollScreen);
  const location = useLocation();
  const dispatch = useAppDispatch();

  return (
    <>
      { isShowLoggedIn && (
        <Modal
          component={(
            <LoggedInModal onExit={() => setIsShowLoggedIn(false)} />
          )}
          exitOnBgClick={() => setIsShowLoggedIn(false)}
        />
      )}
      { isShowSignIn
        && <Modal component={<SignInModal onExit={() => setIsShowSignIn(false)} />} />}
      {
        isShowStaffModal && (
          <Modal
            component={(
              <StaffWarningModal
                onExit={() => setIsShowStaffModal(false)}
                showSignIn={() => {
                  setIsShowStaffModal(false);
                  setIsShowSignIn(true);
                }}
              />
            )}
            exitOnBgClick={() => setIsShowStaffModal(false)}
          />
        )
      }
      <S.FooterContainer>
        <S.FooterSection1>
          <S.JoinUs2><Logo width="28px" fontSize="22px" /></S.JoinUs2>
          <Location />
        </S.FooterSection1>
        <S.FooterSection2>
          <S.Feedback>Feedback</S.Feedback>
          <Social />
        </S.FooterSection2>
        <S.FooterSection>
          <S.Label>CUSTOMERS</S.Label>
          <S.Link
            onClick={() => {
              if (location.pathname === '/rooms') {
                dispatch(setIsScrollScreen(!isScrollAppScreen));
                return;
              }
              history.push('/rooms');
            }}
          >
            Book rooms
          </S.Link>
        </S.FooterSection>
        <S.Copyright>{`Copyright © ${(new Date()).getFullYear()}`}</S.Copyright>
      </S.FooterContainer>
    </>
  );
}

export default Footer;
