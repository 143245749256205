import styled from 'styled-components';

export const Container = styled.div`
  width: 85vw;
  max-width: 340px;
  padding: 20px;
  background: white;
  border-radius: 4px;
`;

export const MainLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const Info = styled.div`
  font-size: 17px;
  margin-top: 3px;
  color: #525252;
`;

export const Info1 = styled.div`
  font-size: 17px;
  margin-top: 50px;
  color: #525252;
`;

export const Button1 = styled.div`
  color: #eb364b;
  margin-top: 15px;
  width: max-content;
  width: 100%;
  display: flex;
  gap: 10px;
`;
