import Color from 'color';
import styled from 'styled-components';
import { textColor2 } from '../LFFooter.styled';

export const MainCont = styled.div`
  width: 100%;
  max-width: 250px;
  font-weight: 400;
  margin: auto;
  margin-top: 18px;
  font-family: Poppins;
  color: ${textColor2};
`;

export const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  margin: auto;
  padding: 0;
  overflow: hidden;
  gap: 5px;
`;

export const LogoCont1 = styled.div`
  width: 25px;
  height: 100%;
  position: relative;
`;

export const LogoCont = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.div`
  transform: scale(0.7);
`;

export const Address = styled.div`
  font-size: calc(13px + 0.1vw);
  line-height: 1.75;
  padding: 0px;
  color: ${Color(textColor2).darken(0.15).toString()};
  width: max-content;
`;

export const Container2 = styled.div`
  width: max-content;
  display: flex;
  margin: auto;
  margin-top: 13px;
`;

export const Number = styled.div`
  font-size: calc(13px + 0.1vw);
  margin-left: 10px;
  line-height: 2;
  margin-top: -2px;
  color: ${Color(textColor2).darken(0.15).toString()};
  cursor: pointer;
  &:hover {
    color: white;
  }
`;

export const Logo2 = styled.div`
  transform: scale(0.5);
`;
