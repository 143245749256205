import React from 'react';
import * as S from './CartBar.styled';
import Cart from '@mui/icons-material/ShoppingCart';
import { useAppSelector } from 'redux/hooks';
import { selectCart } from 'pages/App/redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const CartBar = ({
  isInfoBar
} : {
  isInfoBar?: boolean,
}) => {
  const cart = useAppSelector(selectCart);
  const history = useHistory();
  const location = useLocation();

  if (location.pathname === '/') return null;

  return (
    <S.Container
      isInfoBar={isInfoBar || false}
      onClick={() => {
        if (cart.length) {
          history.push('/cart');
        } else {
          toast('Cart is empty', { type: 'warning' });
        }
      }}
    >
      <S.CartBar isInfoBar={isInfoBar || false}>
        Cart
        <Cart fontSize="small" sx={{ scale: '0.675' }} />
      </S.CartBar>
      <S.CartRoomBar>{cart.length}</S.CartRoomBar>
    </S.Container>
  );
};

export default CartBar;
