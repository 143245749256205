import React, { useEffect } from 'react';
import * as S from './InfoBar.styled';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectCart } from 'pages/App/redux';
import { selectToken } from 'redux/global';
import { useWindowSize } from 'pages/Logs/hooks';
import CartBar from 'pages/components/CartBar/CartBar';
import { headerRes1 } from 'pages/components/LBHeader/LBHeader.styled';
import { LodgerBee } from 'pages/components/LBHeader/LBHeader';
import { usePath } from 'pages/hooks';

const InfoBar = () => {
  const location = useLocation();
  const cart = useAppSelector(selectCart);
  const token = useAppSelector(selectToken);
  const { width: wWidth } = useWindowSize();
  const { isHomePath } = usePath();

  const customerRes = Number(headerRes1.replace('px', ''));
  const isShowCart = !token && wWidth && wWidth < customerRes;
  useEffect(() => {
    const iBCont = document.getElementById('iB_cont');
    if (iBCont && isShowCart) {
      iBCont.style.display = 'flex';
      iBCont.style.height = '45px';
    } else if (iBCont) {
      iBCont.style.display = 'none';
      iBCont.style.height = '0px';
    }
  }, [cart, wWidth, location]);

  if (isHomePath || location.pathname === '/cart') {
    return null;
  }

  return (
    <S.Container id="iB_cont">
      <S.Cont1>
        {
          isShowCart && !token ? (
            <>
              <LodgerBee isWeb={false} isMobile />
              <CartBar isInfoBar />
            </>
          ) : (null)
        }
      </S.Cont1>
    </S.Container>
  );
};

export default InfoBar;
