import styled from 'styled-components';

export const Container = styled.div`
  width: 85vw;
  max-width: 340px;
  padding: 20px;
  background: white;
  border-radius: 4px;
`;

export const MainLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Label = styled.div`
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 500;
`;

export const Label1 = styled.div`
  font-size: 18px;
  margin-top: 10px;
  font-size: 500;
`;

export const Input = styled.input<{ error?: boolean }>`
  font-size: 15px;
  padding-left: 7px;
  margin-top: 5px;
  height: 35px;
  width: 100%;
  color: black;
  border: ${({ error }) => error && '1px solid red'};
  &:focus {
    outline: none;
    border-color: ${({ error }) => error && 'red'};
  }
`;

export const Error = styled.div`
  font-size: 13px;
  margin-top: 3px;
  margin-bottom: 5px;
  color: red;
`;

export const Button1 = styled.div`
  color: #1685ec;
  margin-top: 30px;
  width: max-content;
  width: 100%;
`;

export const Select = styled.select`
  width: 100%;
  max-width: 160px;
  height: 38px;
  padding-left: 5px;
  margin-top: 5px;
`;
