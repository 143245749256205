import styled from 'styled-components';

export const Container = styled.div`
  width: 90vw;
  height: max-content;
  max-width: 220px;
  padding: 20px;
  background: white;
  border-radius: 3px;
`;

export const MainLabel = styled.div`
  font-size: 20px;
  color: black;
  margin-bottom: 35px;
  font-weight: 700;
`;

export const Button1 = styled.div`
  color: #1685ec;
  margin-top: 20px;
  width: max-content;
`;
