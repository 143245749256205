import styled from 'styled-components';
import {
  Button,
  borderColor,
  mobileRes2,
  topBarColor
} from 'pages/styles';
import Color from 'color';

export const PerkOptions = styled.div`
  display: flex;
  gap: 13px;
`;

export const roomGridRes1 = '1529px';
export const roomGridRes2 = '1200px';
export const roomGridRes3 = '1017px';
export const roomGridRes4 = '755px';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  z-index: 1;
  padding: 0px;
`;

export const NoRoomsCont = styled.div`
  margin: auto auto 0 auto;
  width: max-content;
  background: white;
  padding: 13px 28px;
  padding-bottom: 20px;
  border-radius: 4px;
  transform: translateY(-7px);
  border: 1px solid ${borderColor};
`;

export const NoRooms = styled.div`
  font-size: 22px;
  margin: auto;
  width: max-content;
  font-weight: 700;
`;

export const NoRoomsInfo = styled.div`
  font-size: 16px;
  color: #525252;
  width: 100%;
  text-align: center;
  margin-top: 0px;
`;

export const NoRoomsInfo1 = styled.div`
  font-size: 13px;
  color: #999999;
  width: 100%;
  text-align: center;
  margin-top: 5px;
`;

export const ReloadPage = styled.div`
  color: #1685ec;
  font-size: 12.5px;
  text-decoration: underline;
  font-weight: 300;
  width: max-content;
  margin: auto;
  margin-top: 7px;
  cursor: pointer;
  &:hover {
    color: blue;
  }
`;

export const AllRooms = styled.div<{ token: boolean, isFewRooms: boolean }>`
  display: grid;
  grid-template-columns:
    minmax(calc((100% / 3) - (18px * 2/3)), 1fr)
    minmax(calc((100% / 3) - (18px * 2/3)), 1fr)
    minmax(calc((100% / 3) - (18px * 2/3)), 1fr);
  grid-gap: 18px;
  width: 100%;
  max-width: 1420px;
  padding-bottom: 18px;
  margin: 0 auto auto auto;
  @media(min-width: ${roomGridRes4}) {
    display: ${({ isFewRooms }) => isFewRooms && 'flex'};
    width: ${({ isFewRooms }) => isFewRooms && 'max-content'};
  }
  @media(max-width: ${roomGridRes1}) {
    grid-template-columns:
      minmax(calc((100% / 3) - (11px * 2/3)), 1fr)
      minmax(calc((100% / 3) - (11px * 2/3)), 1fr)
      minmax(calc((100% / 3) - (11px * 2/3)), 1fr);
    grid-gap: 11px;
    padding-bottom: 11px;
  }
  @media(max-width: ${roomGridRes2}) {
    grid-template-columns:
      minmax(calc(50% - (18px * 1/2)), 1fr)
      minmax(calc(50% - (18px * 1/2)), 1fr);
    grid-gap: 18px;
    padding-bottom: 18px;
    max-width: 880px;
  }
  @media(max-width: ${roomGridRes3}) {
    grid-template-columns:
      minmax(calc(50% - (11px * 1/2)), 1fr)
      minmax(calc(50% - (11px * 1/2)), 1fr);
    grid-gap: 11px;
    padding-bottom: 11px;
  }
  @media(max-width: ${roomGridRes4}) {
    grid-template-columns: minmax(100%, 1fr);
    max-width: 440px;
  }
  @media(max-width: 500px) {
    overflow-x: hidden;
  }
`;

export const AllRoomsLF = styled.div<{ token: boolean, isFewRooms: boolean }>`
  display: grid;
  grid-template-columns:
    minmax(calc((100% / 4) - (18px * 3/4)), 1fr)
    minmax(calc((100% / 4) - (18px * 3/4)), 1fr)
    minmax(calc((100% / 4) - (18px * 3/4)), 1fr)
    minmax(calc((100% / 4) - (18px * 3/4)), 1fr);
  grid-gap: 18px;
  width: 100%;
  max-width: 100%;
  padding-bottom: 18px;
  margin: 0 auto auto auto;
  @media(min-width: ${roomGridRes4}) {
    display: ${({ isFewRooms }) => isFewRooms && 'flex'};
    width: ${({ isFewRooms }) => isFewRooms && 'max-content'};
  }
  @media(max-width: ${roomGridRes1}) {
    grid-template-columns:
      minmax(calc((100% / 4) - (11px * 3/4)), 1fr)
      minmax(calc((100% / 4) - (11px * 3/4)), 1fr)
      minmax(calc((100% / 4) - (11px * 3/4)), 1fr)
      minmax(calc((100% / 4) - (11px * 3/4)), 1fr);
    grid-gap: 11px;
    padding-bottom: 11px;
  }
  @media(max-width: ${roomGridRes2}) {
    grid-template-columns:
      minmax(calc((100% / 3) - (18px * 2/3)), 1fr)
      minmax(calc((100% / 3) - (18px * 2/3)), 1fr)
      minmax(calc((100% / 3) - (18px * 2/3)), 1fr);
    grid-gap: 18px;
    padding-bottom: 18px;
  }
  @media(max-width: ${roomGridRes3}) {
    grid-template-columns:
      minmax(calc((100% / 3) - (11px * 2/3)), 1fr)
      minmax(calc((100% / 3) - (11px * 2/3)), 1fr)
      minmax(calc((100% / 3) - (11px * 2/3)), 1fr);
    grid-gap: 11px;
    padding-bottom: 11px;
  }
  @media(max-width: ${roomGridRes4}) {
    grid-template-columns:
      minmax(calc(50% - (11px * 1/2)), 1fr)
      minmax(calc(50% - (11px * 1/2)), 1fr);
  }
  @media(max-width: 500px) {
    grid-template-columns: minmax(100%, 1fr);
    overflow-x: hidden;
  }
`;

const updatedBorderSize = 1.5;
export const RoomsCont = styled.div<{ isJustUpdated: boolean, roomAmount: number }>`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${({ isJustUpdated }) => isJustUpdated && `calc(100% - ${updatedBorderSize + 2}px)`};
  @media(min-width: ${roomGridRes4}) {
    width: ${({ roomAmount, isJustUpdated }) => roomAmount === 1 ? (isJustUpdated ?
    `calc(83.5vw - ${updatedBorderSize + 2}px)` : '83.5vw') : roomAmount === 2 && (isJustUpdated ?
    `calc(41.75vw - ${updatedBorderSize + 2}px)` : '41.75vw')}; 
    max-width: ${({ roomAmount }) => roomAmount <= 2 && '440px'};
  }
`;

export const orange = Color('#d6a615').darken(0).toString();
export const Room = styled.div<{ isJustUpdated: boolean }>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: max-content;
  overflow: hidden;
  margin: ${({ isJustUpdated }) => isJustUpdated && `${updatedBorderSize}px ${updatedBorderSize - 0.5}px`};
  outline: ${({ isJustUpdated }) => isJustUpdated && `${updatedBorderSize}px solid
    ${Color(orange).darken(0.2).toString()}`};
  border-radius: 3px;
`;

export const ImageandBookedSection = styled.div`
  width: 100%;
  position: relative;
`;

export const ImageSection = styled.div<{ height: number, isJustUpdated: boolean }>`
  height: ${({ height, isJustUpdated }) => `${height -
    (isJustUpdated ? updatedBorderSize > height ? 0 : updatedBorderSize : 0)}px`};
  width: 100%;
  overflow: hidden;
  background: #999999;
  line-height: 0;
  &:hover {
    cursor: pointer;
  }
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  z-index: 2;
  position: relative;
`;

export const JustUpdated = styled.div`
  position: absolute;
  top: -3px;
  left: -3px;
  font-size: 13px;
  padding: 5.5px 8px;
  padding-bottom: 6px;
  padding-right: 15px;
  background: ${orange};
  font-weight: 500;
  color: ${Color(orange).darken(0.575).toString()};
  border-bottom-right-radius: 7px;
  display: flex;
  align-items: center;
  gap: 0px;
`;

export const Star = styled.div`
  color: ${Color(orange).lighten(0.8).toString()};
  margin-top: 1px;
`;

export const IconImageCont = styled.div`
  height: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: grey;
`;

export const Image = styled.img`
  width: auto;
  min-width: 100%;
  height: 100%;
  z-index: 2;
`;

export const BookedCont = styled.div<{ altBg?: number, width: number }>`
  position: absolute;
  min-width: ${({ altBg }) => altBg === 2 ? '0' : altBg === 1 ? 'calc(100% - 101px)' : '65%'};
  min-width: ${({ altBg, width }) => (altBg === 1 && width <= 378) && 'calc(100% - 41px)'};
  max-width: calc(100% - 34px);
  width: max-content;
  height: auto;
  bottom: 17px;
  left: 17px;
  z-index: 5;
`;

export const BookedContRel = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

export const BookedBg = styled.div<{ altBg?: 1 | 2 }>`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: ${({ altBg }) => altBg === 1 ? '#dce673' : altBg === 2 ? 'white' : '#56d651'};
  opacity: 1;
  border-radius: 6px;
  box-shadow: 0 0 1.5px 0px #383838;
`;

export const MediaFont = styled.div<{ width: number }>`
  font-size: 13px;
  font-size: ${({ width }) => width <= 290 && '11px'};
  font-size: ${({ width }) => width <= 240 && '9px'};
`;

export const BookedText = styled(MediaFont)`
  position: relative;
  min-height: max-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 20px;
  color: ${Color('#525252').darken(0.4).toString()};
`;

export const BookedBy = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const BookedTill = styled(MediaFont)`
  color: #636363;
  margin-left: -8px;
  display: flex;
  align-items: center;
`;

const maxWidth = 390;
export const BookedTill2 = styled.div<{ width: number }>`
  margin-top: -1px;
  margin-left: -1px;
  color: ${Color('#525252').lighten(0.15).toString()};
  font-size: ${({ width }) => width <= maxWidth ? `calc(13px - ${`${(maxWidth - width) / 31}px`})` : '13px'};
`;

export const BookedInfo = styled(MediaFont)`
`;

export const BookedInfo1 = styled(MediaFont)`
`;

export const BookedDetails = styled.div`
  position: absolute;
  bottom: 15px;
  right: 16px;
  border-radius: 3px;
  padding: 3px 7px;
  padding-top: 2px;
  font-size: 10px;
  border: 1px solid ${Color('#dce673').darken(0.5).toString()};
  color: ${Color('#dce673').darken(0.5).toString()};
  cursor: pointer;
  &:hover{
    background: ${Color('#dce673').lighten(0.2).toString()};
  }
`;

export const DetailsSection = styled.div<{ isManagerView: boolean, isJustUpdated: boolean }>`
  height: max-content;
  width: 100%;
  padding: 17px 18px;
  background: white;
  padding-bottom: ${({ isManagerView }) => isManagerView ? '16px' : '19px'};
  position: relative;
  margin-top: 0px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid ${Color(borderColor).lighten(0.2).toString()};
  border-top: none;
  border: ${({ isJustUpdated }) => isJustUpdated && 'none'};
  z-index: 3;
  box-shadow: 0px -0.5px 0.5px 0px rgba(0,0,0,0.15);
`;

export const FlexPart = styled.div`
  display: flex;
`;

export const ToolsPart = styled.div<{ isJustUpdated: boolean }>`
  background: white;
  gap: 13px;
  z-index: 4;
  margin-top: 25px;
  margin-top: ${({ isJustUpdated }) => isJustUpdated && `${25 - (updatedBorderSize - 2)}px`};
  display: flex;
  align-items: flex-end;
  color: ${Color(topBarColor).lighten(0.2).toString()};
`;

export const Tool = styled.div`
  border: 1px solid ${Color(topBarColor).lighten(0.2).toString()};
  border-radius: 9px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  padding: 2px 10px;
  padding-bottom: 3px;
  transform: translateY(4px);
  cursor: pointer;
  width: max-content;
  &:hover {
    color: ${topBarColor};
    border-color: ${topBarColor};
    background: ${Color(topBarColor).lighten(0.9).toString()};
  }
`;

export const ToolText = styled.div`
  font-size: 8px;
  font-weight: 700;
  margin-top: -5px;
  font-family: Poppins;
`;

export const RoomDetail = styled(Tool)`
  border: 1px solid #ababab;
`;

export const FloorPart = styled.div`
  transform: translateY(5px);
`;

export const FloorCont = styled.div`
  padding: 3px 10px;
  padding-bottom: 4px;
  border-radius: 4px;
  width: max-content;
  margin: auto;
  background: ${Color('#f0f0f0').darken(0.015).toString()};
  @media(max-width: ${mobileRes2}) {
    padding: 4px 10px;
    padding-bottom: 3px;
  }
`;

export const FloorNum = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: ${Color('#8f8f8f').darken(0.075).toString()};
  display: flex;
  align-items: flex-end;
`;

export const Suffix = styled.div`
  font-size: 11px;
  font-weight: 100;
`;

export const Floor = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${Color('#ababab').darken(0.075).toString()};;
  text-align: center;
  padding-top: 1px;
  width: 100%;
  margin: auto;
  margin-left: auto;
  width: max-content;
`;

export const NamePart = styled.div`
  flex: 1;
`;

export const NameAndButton = styled.div`
  display: flex;
`;

export const NameAndPrice = styled.div`
`;

export const RightPart = styled.div`
  transform: translateY(1px);
  margin-left: auto;
`;

export const RoomName = styled.div<{ width: number }>`
  font-weight: 700;
  font-size: 21px;
  margin-top: -3px;
  line-height: 1.7;
  max-width: ${({ width }) => `calc(${width}px - 135px)`};
  font-size: ${({ width }) => width < 290 && '14px'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const RoomPrice = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: grey;
  margin-top: -3px;
`;

export const PerksCont = styled.div`
  height: 20px;
  width: max-content;
  z-index: 6;
`;

export const ButtonSection = styled.div`
  height: max-content;
`;

export const Button1 = styled.div`
  color: ${Color('#56d651').darken(0.4).toString()};
  width: max-content;
`;

export const Button0 = styled(Button)<{ disabled?: boolean }>`
  color: ${Color('#56d651').darken(0.625).toString()};
  background: ${Color('#56d651').lighten(0.05).toString()};
  border-radius: 3px;
  padding: 8px 16px;
  font-size: 13px;
  color: ${({ disabled }) => disabled && Color('#525252').lighten(0.2).toString()};
  border: ${({ disabled }) => disabled && `1px solid ${Color('#525252').lighten(0.2).toString()}`}; 
  background: ${({ disabled }) => disabled && 'inherit'}; 
  opacity: ${({ disabled }) => disabled && '0.5'};
  margin-top: -3px;
  transform: translateX(4px);  
  cursor: ${({ disabled }) => disabled && 'default'}; 
  &:hover {
    background: ${({ disabled }) => !disabled ? Color('#56d651').lighten(0.4).toString() : 'inherit'};
  }
  @media(max-width: ${mobileRes2}) {
    font-size: 12px;
  }
  @media(max-width: 320px) {
    padding: 7px 11px;
    font-size: 11px;
  }
`;

export const Button2 = styled(Button0)`
  color: ${Color('#dce673').darken(0.6).toString()};
  background: ${Color('#dce673').lighten(0.05).toString()};
  &:hover {
    background: ${Color('#dce673').lighten(0.3).toString()};
  }
`;

export const DescriptionCont = styled.div`
  background: white;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin-top: 20px;
  z-index: 9;
`;

export const DetailCont = styled.div<{ isJustUpdated: boolean }>`
  padding: 15px 17px;
  padding-right: 15px;
  padding-bottom: 16px;
  border-radius: 7px;
  background: #f0f0f0;
  display: flex;
  background: ${({ isJustUpdated }) => isJustUpdated && Color(orange).lighten(1.05).toString()};
  @media(max-width: ${mobileRes2}) {
    padding: 17px 17px;
    padding-bottom: 15px;
  }
`;

export const BlockCont = styled.div`
`;

export const Detail = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  width: max-content;
  max-width: 100%;
  max-height: 20px;
  overflow: hidden;
  font-size: 11.5px;
  @media(max-width: 360px) {
    font-size: calc(1.5vw + 4.5px);
  }
`;

export const Detail1 = styled(Detail)`
  margin-top: 5px;
  @media(max-width: 360px) {
    margin-top: 8px;
  }
`;

export const DetailsHeader = styled.div`
  font-size: 9.75px;
  transform: translate(1px, -1px);
  font-weight: 500;
  color: ${Color('#6b6b6b').lighten(0.2).toString()};
  @media(max-width: 360px) {
    font-size: calc(2vw + 2.5px);
    transform: translate(1px, 0px);
  }
`;

export const Details = styled.div`
  color: #4f4f4f;
`;

export const InspectCont = styled.div<{ isJustUpdated: boolean }>`
  padding: 4px 7px;
  padding-top: 1px;
  border: 0.9px solid ${Color('#6b6b6b').lighten(0.375).toString()};
  color: ${Color('#6b6b6b').lighten(0.35).toString()};
  color: ${({ isJustUpdated }) => isJustUpdated && Color(orange).darken(0.2).toString()};
  border-color: ${({ isJustUpdated }) => isJustUpdated && Color(orange).darken(0.2).toString()};
  display: flex;
  flex-direction: column;
  margin-top: auto;
  transform: translate(-1px, -2px);
  margin-left: auto;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background: ${Color('lightgrey').lighten(0.05).toString()};
    border: 1px solid ${Color('#6b6b6b').lighten(0).toString()};
    color: ${Color('#6b6b6b').lighten(0).toString()};
  }
  @media(max-width: ${mobileRes2}) {
    padding: 2.5px 7px;
    padding-top: 1px;
    transform: translate(-1px, -1.5px);
  }
`;

export const InspectText = styled.div`
  font-size: 9.5px;
  margin: auto;
  margin-top: -5px;
  @media(max-width: ${mobileRes2}) {
    margin-top: -3px;
  }
`;
