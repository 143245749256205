import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Loader from 'pages/components/Loader/Loader';
import { useAppSelector } from 'redux/hooks';
import {
  selectPermission, selectToken,
} from 'redux/global';
import Layout from 'pages/Layout/Layout';

const LBHomepage = lazy(() => import('pages/LBHomepage/LBHomepage'));
const LFHomepage = lazy(() => import('pages/LFHomepage/LFHomepage'));
const App = lazy(() => import('pages/App/App'));
const Logs = lazy(() => import('pages/Logs/Logs'));
const Metrics = lazy(() => import('pages/Metrics/Metrics'));
const Settings = lazy(() => import('pages/Settings/Settings'));
const NotFound = lazy(() => import('pages/NotFound/NotFound'));
const Forgot = lazy(() => import('pages/Forgot/Forgot'));
const SetPassword = lazy(() => import('pages/SetPassword/SetPassword'));
const Register = lazy(() => import('pages/Register/Register'));
const RegisterPassword = lazy(() => import('pages/RegisterPassword/RegisterPassword'));
const AddEditRoom = lazy(() => import('pages/AddEditRoom/AddEditRoom'));
const Cart = lazy(() => import('pages/Cart/Cart'));
const Reservations = lazy(() => import('pages/Reservations/Reservations'));
const Insights = lazy(() => import('pages/Insights/Insights'));
const SignIn = lazy(() => import('pages/SignIn/SignIn'));
const SignUp = lazy(() => import('pages/SignUp/SignUp'));
const AddHotel = lazy(() => import('pages/AddHotel/AddHotel'));

(document.body.style as any).zoom = '100.06%';

const Routing = function Routing() {
  const token = useAppSelector(selectToken);
  const permission = useAppSelector(selectPermission);
  const isLodgeFirst = process.env.REACT_APP_VIEW === 'LodgeFirst';

  // const Routes = () => (
  //   <Route
  //     exact
  //   path={['/rooms/:toBook', '/cart', '/metrics', '/logs/:action', '/reservations', '/settings',
  //       '/add', '/edit/:id', '*']}
  //   >
  //     <Switch>
  //     </Switch>
  //   </Route>
  // );

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Layout>
          <Switch>
            <Route exact path="/">
              {isLodgeFirst ? <LFHomepage /> : <LBHomepage />}
            </Route>
            <Route exact path="/forgot">
              <Forgot />
            </Route>
            <Route exact path="/forgot/:key">
              <SetPassword />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/register/:key">
              <RegisterPassword />
            </Route>
            {
              isLodgeFirst && (
                <Route exact path={['/signin', '/signup', '/addH/:id']}>
                  <Route exact path="/signin">
                    <SignIn />
                  </Route>
                  <Route exact path="/signup">
                    <SignUp />
                  </Route>
                  <Route exact path="/addH/:id">
                    <AddHotel />
                  </Route>
                </Route>
              )
            }
            {/* <Switch><Routes /></Switch> */}
            {
              !token && (
                <Route exact path={['/rooms/:toBook', '/cart']}>
                  <Route exact path="/rooms/:toBook">
                    <App />
                  </Route>
                  <Route exact path="/cart">
                    <Cart />
                  </Route>
                </Route>
              )
            }
            {
              token && (
                <Route exact path="/logs">
                  <Logs />
                </Route>
              )
            }
            {
              token && (permission || 0) >= 2 && (
                <Route exact path={['/add', '/edit/:id']}>
                  <AddEditRoom />
                </Route>
              )
            }
            {
              (!token || (permission || 0) > 0) && (
                <Route exact path="/rooms">
                  <App />
                </Route>
              )
            }
            {
              (!token || (permission || 0) >= 3) && (
                <Route exact path="/insights">
                  <Insights />
                </Route>
              )
            }
            {
              (token && (permission || 0) >= 3) && (
                <Route exact path={['/metrics', '/logs/:action', '/reservations', '/settings', '*']}>
                  <Switch>
                    <Route exact path="/metrics">
                      <Metrics />
                    </Route>
                    <Route exact path="/logs/:action">
                      <Logs />
                    </Route>
                    <Route exact path="/reservations">
                      <Reservations />
                    </Route>
                    <Route exact path="/settings">
                      <Settings />
                    </Route>
                    <Route exact path="*">
                      <NotFound />
                    </Route>
                  </Switch>
                </Route>
              )
            }
            <Route exact path="*">
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </Suspense>
    </Router>
  );
};

export default Routing;
