import React from 'react';
import logo from 'assets/client/logo.jpg';
import * as S from './Logo.styled';
import { useAppSelector } from 'redux/hooks';
import { selectHotelData } from 'pages/redux';

const Logo = function Logo({ width, fontSize }:{ width?: string, fontSize?: string }) {
  const hotelData = useAppSelector(selectHotelData);

  return (
    <S.Container width={width}>
      {
        process.env.REACT_APP_LOGO && <S.Image src={logo} width={width} />
      }
      <S.Label fontSize={fontSize}>{hotelData?.name}</S.Label>
    </S.Container>
  );
};

export default Logo;
