import React from 'react';
import * as S from './StaffWarningModal.styled';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import { Button, topBarColor } from 'pages/styles';

const StaffWarningModal = (
  {
    onExit, showSignIn,
  } : {
    onExit: Function, showSignIn: Function,
  }
) => {
  return (
    <S.Container>
      <EscapeButton onClick={onExit} />
      <S.MainLabel>Signing in</S.MainLabel>
      <S.Label>
        Signing in is for only staffs of
        {' '}
        {process.env.REACT_APP_HOTEL_NAME}
        {' '}
        with access permissions.
      </S.Label>
      <S.Button1>
        <Button bg="white" color={topBarColor} hvBg="#d5dbe6" onClick={() => onExit()}>
          Back
        </Button>
        <Button onClick={() => showSignIn()}>Proceed</Button>
      </S.Button1>
    </S.Container>
  );
};

export default StaffWarningModal;
