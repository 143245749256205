import styled from 'styled-components';

export const Container = styled.div`
  width: 90vw;
  height: max-content;
  max-width: 400px;
  padding: 20px;
  background: white;
  border-radius: 3px;
  color: black;
`;

export const MainLabel = styled.div`
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: 700;
`;

export const Label = styled.div`
  font-size: 17px;
  margin-bottom: 30px;
  color: #525252;
`;

export const Label1 = styled.div`
  font-size: 17px;
  font-weight: 500;
`;

export const Button1 = styled.div`
  margin-top: 50px;
  width: max-content;
  display: flex;
  gap: 10px;
`;
