import styled from 'styled-components';
import { headerColor, textColor2 } from '../Footer.styled';

export const Container = styled.div`
  width: max-content;
  margin: auto;
  margin-top: 12px;
  display: flex;
  gap: 15px;
`;

export const IconContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 61px;
  height: 58px;
  background: ${textColor2};
  border-radius: 12px;
  color: ${headerColor};
  cursor: pointer;
  &:hover {
    background: white;
  }
`;

export const Icon = styled.div`
  transform: scale(0.55);
`;
