import { useLocation } from 'react-router-dom';

export const usePath = () => {
  const homePaths = ['/signin', '/register', '/signup', '/forgot', '/addH'];
  const location = useLocation();

  const isHomePath = !!(location.pathname === '/' || homePaths.find((p) => location.pathname.includes(p)));

  return { isHomePath };
};
