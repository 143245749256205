import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IResponse } from 'global/types';
import { callEndpoint } from 'redux/global';
import { RootState } from 'redux/store';

export interface HomepageStatey {
  isStaff: boolean,
  isFirstLoad: boolean,
  hotelDataName: string,
}

const initialState: HomepageStatey = {
  isStaff: Boolean(localStorage.getItem('isStaff')),
  isFirstLoad: true,
  hotelDataName: localStorage.getItem('hotel_data_name') || '',
};

export const counterSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    setIsStaff: (state, action: PayloadAction<boolean>) => {
      state.isStaff = action.payload;
      if (action.payload) {
        localStorage.setItem('isStaff', 'Yes');
      } else {
        localStorage.removeItem('isStaff');
      }
    },
    setIsFirstLoad: (state, action: PayloadAction<boolean>) => {
      state.isFirstLoad = action.payload;
    },
    setHotelDataName: (state, action: PayloadAction<string>) => {
      state.hotelDataName = action.payload;
      localStorage.setItem('hotel_data_name', action.payload);
    },
  },
});

export const { setIsStaff, setIsFirstLoad, setHotelDataName } = counterSlice.actions;

export const selectIsStaff = (state: RootState) => state.homepage.isStaff;
export const selectIsFirstLoad = (state: RootState) => state.homepage.isFirstLoad;
export const selectHotelDataName = (state: RootState) => state.homepage.hotelDataName;

export default counterSlice.reducer;

type LogInDetails = {
  email: string,
  password: string
}

export const getLogInBranches = (logInDetails: LogInDetails) => async (dispatch: Function)
  : Promise<IResponse> => {
  const res: IResponse = await dispatch(
    callEndpoint({
      api: 'getloginbranches',
      method: 'POST',
      body: { logInDetails }
    }),
  );
  return res;
};
