import { getOrdinalSuffix } from './common';

export const formatN = (num: number, decimal = 1) => {
  let isNegative = false;
  if (num < 0) {
    isNegative = true;
    num *= -1;
  }
  let symbol = '';
  if (num >= 1e12) {
    num /= 1e12;
    symbol = 'T';
  } else if (num >= 1e9) {
    num /= 1e9;
    symbol = 'B';
  } else if (num >= 1e6) {
    num /= 1e6;
    symbol = 'M';
  } else if (num >= 1000) {
    num /= 1000;
    symbol = 'k';
  }
  return `${isNegative ? '-' : ''}${num.toFixed(decimal).replace(/\.0$/, '')}${symbol}`;
};

export const convertTime = (time: string) => {
  const parts = time.split(':');
  let suffix = 'am';
  if (Number(parts[0]) >= 12) {
    suffix = 'pm';
    if (Number(parts[0]) > 12) {
      parts[0] = (Number(parts[0]) - 12).toString();
    }
  }
  if (parts[0].length < 2) {
    parts[0] = `0${parts[0]}`;
  }
  if (parts[1].length < 2) {
    parts[1] = `0${parts[1]}`;
  }
  return `${parts.join(':')}${suffix}`;
};

export const convertDate = (date: Date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const ret = `${date.getDate()}${getOrdinalSuffix(date.getDate())} ${months[date.getMonth()]}${date.getFullYear() !== (
    new Date()).getFullYear() ? ` ${date.getFullYear()}.` : ''}`;
  const date2 = new Date();
  const ret1 =
    `${date2.getDate()}${getOrdinalSuffix(date2.getDate())} ${months[date2.getMonth()]}${date2.getFullYear() !== (
      new Date()).getFullYear() ? ` ${date2.getFullYear()}.` : ''}`;
  return `${ret === ret1 ? 'Today' : ret} ${convertTime(`${date.getHours()}:${date.getMinutes()}`)}`;
};

export const convertDate2 = (date: Date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const ret = `${days[date.getDay()]}, ${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  const date2 = new Date();
  const ret1 = `${days[date2.getDay()]}, ${date2.getDate()} ${months[date2.getMonth()]} ${date2.getFullYear()}`;
  return ret === ret1 ? 'Today' : ret;
};

export const convertDate22 = (date: Date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const ret = `${date.getDate()} ${months[date.getMonth()]}${date.getFullYear() !== (
    new Date()).getFullYear() ? ` ${date.getFullYear()}` : ''}`;
  const date2 = new Date();
  const ret1 = `${date2.getDate()} ${months[date2.getMonth()]}${date2.getFullYear() !== (
    new Date()).getFullYear() ? ` ${date2.getFullYear()}` : ''}`;
  return ret === ret1 ? `Today, ${ret}` : ret;
};

export const convertDate23 = (date: Date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const ret = `${date.getDate()} ${months[date.getMonth()]}${date.getFullYear() !== (
    new Date()).getFullYear() ? ` ${date.getFullYear()}` : ''}`;
  const date2 = new Date();
  const ret1 = `${date2.getDate()} ${months[date2.getMonth()]}${date2.getFullYear() !== (
    new Date()).getFullYear() ? ` ${date2.getFullYear()}` : ''}`;
  return ret === ret1 ? 'Today' : ret;
};

export const convertDate3 = (date: Date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const ret = `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`;
  return ret;
};

export const isValidatedEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|.('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const truncate = (str: string, max?: number) => {
  max = max || 18;
  if (str.length > max) {
    return `${str.substring(0, max)}...`;
  }
  return str;
};

export const getBookToken = (id: string | undefined) => {
  return `${Math.random().toString(36).slice(2, 8)}${(id || '0').toString().split('')[0]}`.toUpperCase();
};

// eslint-disable-next-line no-unused-vars
export const getMerchantFee = (total: number) => {
  // const totalPlusPayFee = total * (103 / 100);
  // const payFee = totalPlusPayFee - total > 4000 ? 4000 : totalPlusPayFee - total;
  // const my500extra = total >= 20000 ? 500 : 0;
  const merchantFee = 500;// (Math.ceil((payFee + my500extra) / 100) * 100) + 200 + 300;
  return merchantFee;
};

export const convertTime2 = (date: Date) => {
  return `${convertTime(`${date.getHours()}:${date.getMinutes()}`)}`;
};

const convertTime22 = (date: Date) => {
  return `${convertTime(`${date.getHours()}:${date.getMinutes()}`)}:${date.getSeconds() < 10 ? `0${date.getSeconds()}`
    : date.getSeconds()}`;
};

export const getTaxRef = (name: string, email: string, amount: string) => {
  return `payment:FOR-${name}-${email}_AMT:${amount}_${convertDate3(new Date())}_${convertTime22(new Date())}`;
};
