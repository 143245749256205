import {
  auditsPanelMobileHeight,
  bgColor,
  logRes1,
  mobileRes2,
  topBarColor2,
} from 'pages/styles';
import styled from 'styled-components';

export const scrollBarRes = '1150px';
export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  z-index: 1;
  background: ${bgColor};
  @media(max-width: ${logRes1}) {
    flex-direction: column;
  }
`;

export const AuditsPanelMobile = styled.div`
  display: none;
  width: 100%;
  height: ${auditsPanelMobileHeight};
  box-shadow: 0px -1px 2px 2px rgba(0,0,0,0.17);
  -webkit-box-shadow: 0px -1px 2px 2px rgba(0,0,0,0.17);
  -moz-box-shadow: 0px -1px 2px 2px rgba(0,0,0,0.17);
  position: sticky;
  top: 0;
  z-index: 15;
  background: white;
  padding: 13px 19px;
  padding-right: 16px;
  @media(max-width: ${logRes1}) {
    display: flex;
    align-items: flex-end;
    font-weight: 500;
  }
  @media(max-width: ${mobileRes2}) {
    box-shadow: 0px -1px 2px 2px rgba(0,0,0,0.37);
    -webkit-box-shadow: 0px -1px 2px 2px rgba(0,0,0,0.37);
    -moz-box-shadow: 0px -1px 2px 2px rgba(0,0,0,0.37);
    padding: 13px 14px;
    padding-right: 13px;
  }
`;

export const AuditsText = styled.div`
  font-weight: 700;
  font-size: 15px;
  color: ${topBarColor2};
  transform: translateY(1.5px);
`;
