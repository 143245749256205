import React from 'react';
import * as S from './Logo.styled';
import { LodgerBee } from 'pages/components/LBHeader/LBHeader';

const Logo = function Logo({ width }:{ width?: string }) {
  return (
    <S.Container width={width}>
      <LodgerBee isWeb={false} isMobile={false} />
    </S.Container>
  );
};

export default Logo;
