import Color from 'color';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  padding-bottom: 10px;
  padding-top: 0px;
  overflow-x: auto;
`;

export const AddContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailsLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: grey;
  margin-top: 30px;
  margin-bottom: 15px;
`;

export const Select = styled.select`
  width: 100%;
  max-width: 160px;
  height: 38px;
  padding-left: 5px;
  font-size: 14px;
`;

export const Input = styled.input<{ error?: boolean }>`
  font-size: 14px;
  padding-left: 10px;
  margin-top: 17px;
  height: 38px;
  width: 100%;
  max-width: 240px;
  color: black;
  border: none;
  border-bottom: 1.5px solid ${Color('#525252').lighten(0.9).toString()};
  border-bottom: ${({ error }) => error && '2px solid red'};
  &:focus {
    outline: none;
    border-bottom: 3.5px solid black;
    border-color: ${({ error }) => error && 'red'};
  }
`;

export const Error = styled.div`
  font-size: 14px;
  margin-top: 5px;
  color: red;
`;

export const Details = styled.div`
  margin-top: 10px;
`;

export const DetailsLabel1 = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: grey;
  margin-top: 35px;
  margin-bottom: 15px;
`;

export const AddedCont1 = styled.div`
  max-height: 360px;
  overflow-y: auto;
`;

export const AddedCont = styled.div<{ noTopBorder: boolean }>`
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid grey;
  border-top: ${({ noTopBorder }) => noTopBorder && 'none'};
`;

export const Id = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  color: #525252;
  border-radius: 50%;
  background: lightgrey;
  padding: 10px;
`;

export const Labels = styled.div`
  width: max-content;
  max-width: 200px;
  margin-right: 10px;
  overflow: hidden;
`;

export const AddedLabel = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const AddedLabel1 = styled.div`
  font-size: 14px;
  margin-bottom: 3px;
`;

export const ButtonCont = styled.div`
  color: #eb364b;
  margin-left: auto;
  display: flex;
  gap: 10px;
  width: 141px;
`;

export const IconCont = styled.div<{ color: string, disabled: boolean }>`
  width: calc(50% - 5.5px);
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};
  border: ${({ color }) => `1px solid ${color}`};
  opacity: ${({ disabled }) => disabled && '0.1'};
  border-radius: 5px;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  &:hover {
    color: ${({ disabled }) => !disabled && '#1685ec'};
    border: ${({ disabled }) => !disabled && '1px solid #1685ec'};
  }
`;

export const Icon = styled.div`
`;

export const Text = styled.div`
  font-size: 11px;
  text-align: center;
  font-weight: 500;
`;
