import React from 'react';
import Logo from './Logo/Logo';
import * as S from './LBFooter.styled';
import Location from './Location/Location';

function LBFooter() {
  return (
    <S.FooterContainer>
      <S.FooterSection1>
        <S.JoinUs2><Logo width="28px" /></S.JoinUs2>
        <Location />
      </S.FooterSection1>
      <S.Copyright>{`Copyright © ${(new Date()).getFullYear()}`}</S.Copyright>
    </S.FooterContainer>
  );
}

export default LBFooter;
