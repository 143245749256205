import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  getBranchFiles,
  getLogs,
  selectBranchFiles,
  selectLogs,
  selectLogsChecked,
  setBranchFiles, setLogs, setLogsChecked
} from './redux';
import { toast } from 'react-toastify';
import * as S from './hooks.styled';
import { moveTmpPanel } from 'pages/Navigation/utils';
import { Branch, selectHotelData, setHotelData } from 'pages/redux';
import {
  LogGroups, LogKey, LogType, logGroupsRaw
} from './Logs';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';

export type Log = {
  id: string,
  type: string,
  message: string,
  date: string,
  value: string,
  updatedAsOf: string,
};

export const useLogs = () => {
  const logs = useAppSelector(selectLogs);
  const [loading, setLoading] = useState<boolean>(false);
  const branchFiles = useAppSelector(selectBranchFiles);
  const hotelData = useAppSelector(selectHotelData);
  const { branches } = hotelData || {};
  const logGroups: LogGroups = { ...logGroupsRaw };
  const addBranches = branches?.map((b) => b.name);
  logGroups.Branches = [...(addBranches || []), ...logGroups.Branches];
  const dispatch = useAppDispatch();

  const getLogsFromBranchFiles = () => {
    const addLogs: Log[] = [];
    logGroups.Branches?.forEach((b) => {
      if (branchFiles?.[b]) {
        Object.entries(branchFiles[b]).forEach(([, sheet]) => {
          sheet.forEach((l) => {
            const newLog: Log = {
              id: `F_${b}_${l.sheetName}_${l.id}`,
              type: b,
              message: `&${l.message}& File entry by |${l.updatedBy}|`,
              date: l.date,
              value: l.value.toString(),
              updatedAsOf: l.updatedAsOf
            };
            addLogs.push(newLog);
          });
        });
      }
    });
    return addLogs;
  };

  const fetchLogs = async () => {
    setLoading(true);
    const res = await dispatch(getLogs());
    setLoading(false);
    if (res.status === 'success') {
      dispatch(setLogs([...res.data, ...getLogsFromBranchFiles()]));
      return;
    }
    toast(res.data, { type: 'error' });
  };

  useEffect(() => {
    if (branchFiles) {
      if (!logs) {
        fetchLogs();
      } else {
        const logsFromBranchFiles = getLogsFromBranchFiles();
        const newLogs = [...(logs || [])].filter(
          (l) => !logsFromBranchFiles.find((bL) => bL.id === l.id)
        );
        dispatch(setLogs([...newLogs, ...logsFromBranchFiles]));
      }
    }
  }, [branchFiles]);

  const fetchBranchFiles = async (noToast?: boolean) => {
    setLoading(true);
    const res = await dispatch(getBranchFiles());
    setLoading(false);
    if (res.status === 'success') {
      dispatch(setBranchFiles(JSON.parse(res.data.branchFiles)));
      if (!res.data.branchFiles) fetchLogs();
      return;
    }
    if (!noToast) toast(res.data, { type: 'error' });
  };

  useEffect(() => {
    if (hotelData?.id && !branchFiles && !logs) {
      fetchBranchFiles();
    }
  }, [hotelData]);

  const deleteLocalLog = (id: string | number) => {
    const newLogs = (logs || []).filter((l) => l.id.toString() !== id.toString());
    dispatch(setLogs(newLogs));
  };

  const updateLocalLog = (log: Log) => {
    const newLogs = [...(logs || []).filter((l) => l.id.toString() !== log.id.toString()),
      log];
    dispatch(setLogs(newLogs));
  };

  const updateLocalBranch = (branch: Branch) => {
    const newBranches = [...(branches || []).filter(
      (b) => b.name !== branch?.name
    ), branch];
    if (hotelData) {
      dispatch(setHotelData({ ...hotelData, branches: newBranches }));
    }
  };

  const deleteLocalBranch = (branch: string) => {
    const newBranches = [...(branches || []).filter(
      (b) => b.name !== branch
    )];
    if (hotelData) {
      dispatch(setHotelData({ ...hotelData, branches: newBranches }));
    }
  };

  const deleteLocalBranchFile = (branch: string, sheetName: string) => {
    const newBranchFiles = { ...branchFiles?.[branch] || {} };
    delete newBranchFiles[sheetName];
    dispatch(setBranchFiles({ ...branchFiles, [branch]: newBranchFiles }));
  };

  const deleteLocalBranchFiles = (branch: string) => {
    const newBranchFiles = { ...branchFiles };
    delete newBranchFiles[branch];
    dispatch(setBranchFiles({ ...newBranchFiles }));
  };

  return {
    isLoadingLogs: loading,
    logs,
    logGroups,
    deleteLocalLog,
    updateLocalLog,
    getLogsFromBranchFiles,
    fetchBranchFiles,
    deleteLocalBranchFile,
    deleteLocalBranchFiles,
    updateLocalBranch,
    deleteLocalBranch
  };
};

export const useLogsSelector = ({
  triggerAllItems0,
  triggerAllItems1,
}:{
  triggerAllItems0?: Function,
  triggerAllItems1?: Function,
}) => {
  const checked = useAppSelector(selectLogsChecked);
  const dispatch = useAppDispatch();
  const { logGroups } = useLogs();

  const onAuditsScroll = (e: any) => {
    const scrollT = e.target.scrollTop;
    localStorage.setItem('audits_scroll', (scrollT || 0).toString());
  };

  const scrollAuditsPanel = () => {
    const scrollTop = Number(localStorage.getItem('audits_scroll') || 0);
    document.getElementById('audits0')?.scrollTo({ top: scrollTop });
    document.getElementById('audits1')?.scrollTo({ top: scrollTop });
  };

  useLayoutEffect(() => {
    scrollAuditsPanel();
  }, [checked]);

  const onClickShowAll = (checked1: string[]) => {
    if (checked1.includes('Show all')) {
      dispatch(setLogsChecked([...[]]));
    } else {
      let allItems: string[] = ['Show all'];
      Object.entries(logGroups).forEach(([k, v]) => {
        const allItemsOfThisGroup = [...v, k];
        allItems = [...allItems, ...allItemsOfThisGroup];
      });
      dispatch(setLogsChecked([...allItems]));
      triggerAllItems0?.();
    }
  };

  const onClickAuditKey = (key: LogKey, checked1: string[]) => {
    const value = (logGroups as any)[key];
    if (checked1.includes(key)) {
      const newC = checked1.filter((c) => ![...value, key, 'Show all']
        .includes(c as LogType));
      dispatch(setLogsChecked(newC));
    } else {
      let newC = checked1.filter((c) => !value.includes(c as LogType));
      newC = [...checked1, ...value, key];
      dispatch(setLogsChecked(newC));
      triggerAllItems0?.();
    }
  };

  const onClickAudit = (key: LogKey, logType: string, checked1: string[]) => {
    if (checked1.includes(logType)) {
      const newC = checked1.filter((c) => c !== 'Show all' && c !== key && c !== logType);
      dispatch(setLogsChecked(newC));
    } else {
      const newC = [...checked1, logType];
      dispatch(setLogsChecked(newC));
      triggerAllItems1?.(key, logType);
    }
  };

  const AuditSelector = ({
    isWeb,
    useEscape,
  }:{
    isWeb?: boolean,
    useEscape?: boolean,
  }) => {
    return (
      <S.AuditsCont isWeb={isWeb}>
        {
          useEscape ? <EscapeButton onClick={() => moveTmpPanel()} /> : (
            <S.EsacpePart>
              <EscapeButton onClick={() => moveTmpPanel()} />
            </S.EsacpePart>
          )
        }
        <S.Header isWeb={isWeb}>
          Audits
        </S.Header>
        <S.Audits id={`audits${isWeb ? '0' : '1'}`} onScroll={onAuditsScroll}>
          <S.AuditCont>
            <S.AuditGroup1
              onClick={() => onClickShowAll(checked)}
            >
              Show all
              <S.Select
                type="checkbox"
                checked={checked.includes('Show all')}
                readOnly
              />
            </S.AuditGroup1>
          </S.AuditCont>
          {
            Object.entries(logGroups).map(([key, value]) => {
              return (
                <S.AuditCont key={`AuditSet_${key}`}>
                  <S.AuditGroup
                    onClick={() => onClickAuditKey(key as LogKey, checked)}
                  >
                    {key}
                    <S.Select
                      type="checkbox"
                      checked={checked.includes(key)}
                      readOnly
                    />
                  </S.AuditGroup>
                  {
                    value.map((logType) => {
                      return (
                        <S.AuditName
                          key={`logT_${logType}`}
                          onClick={() => onClickAudit(key as LogKey, logType, checked)}
                        >
                          {logType}
                          <S.Select2
                            type="checkbox"
                            checked={checked.includes(logType)}
                            readOnly
                          />
                        </S.AuditName>
                      );
                    })
                  }
                </S.AuditCont>
              );
            })
          }
        </S.Audits>
      </S.AuditsCont>
    );
  };
  return {
    AuditSelector,
    onClickShowAll,
    onClickAuditKey,
    onClickAudit,
  };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Record<string, undefined | number>>({
    width: 278,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};
