import React from 'react';
import * as S from './LBHeader.styled';
import { useAppSelector } from 'redux/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import ArrowDown from '@mui/icons-material/ArrowDropDown';
import Bee from '@mui/icons-material/EmojiNature';
import { useDispatch } from 'react-redux';
import { selectIsScrollScreen, setIsScrollScreen } from 'pages/App/redux';
import CartBar from '../CartBar/CartBar';
import { selectHotelData } from 'pages/redux';

export const LodgerBee = ({ isWeb, isMobile }:{ isWeb: boolean, isMobile: boolean }) => {
  return (
    <S.LodgerBeeCont isWeb={isWeb} isMobile={isMobile}>
      <S.Lodger>
        Lodger
        {' '}
        <S.Bee>Bee</S.Bee>
      </S.Lodger>
      <S.BeeCont>
        <Bee
          sx={{
            position: 'absolute',
            top: '-14px',
            right: '-14px',
            scale: '0.8'
          }}
          fontSize="large"
        />
      </S.BeeCont>
    </S.LodgerBeeCont>
  );
};

const LBHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const isScrollScreen = useAppSelector(selectIsScrollScreen);
  const hotelData = useAppSelector(selectHotelData);
  const dispatch = useDispatch();

  const Link: React.FC = () => {
    return (
      <S.LinkCont
        onClick={() => {
          if (location.pathname === '/rooms') {
            dispatch(setIsScrollScreen(!isScrollScreen));
          } else {
            history.push('/rooms');
          }
        }}
      >
        <S.Link>Rooms</S.Link>
        <S.LinkIconCont>
          <ArrowDown fontSize="small" />
        </S.LinkIconCont>
      </S.LinkCont>
    );
  };

  return (
    <>
      <S.Container>
        <S.Menu>
          <S.NamePart>
            <S.HotelName
              onClick={() => {
                if (window.location.pathname === '/rooms') {
                  history.push('/');
                } else {
                  history.push('/rooms');
                }
              }}
            >
              {hotelData?.name || <LodgerBee isWeb={false} isMobile={false} />}
            </S.HotelName>
            {!hotelData?.name ? (null) : <Link />}
          </S.NamePart>
          {!hotelData?.name ? (null) : <LodgerBee isWeb isMobile={false} />}
          <MediaQuery minWidth={Number(S.headerRes1.replace('px', ''))}>
            <S.HotelDesc>
              <CartBar />
            </S.HotelDesc>
          </MediaQuery>
        </S.Menu>
      </S.Container>
    </>
  );
};

export default LBHeader;
