import { useEffect, useState } from 'react';
import {
  BookRoomDetails,
  bookRoom,
  getRoomImage,
  getRoomImages,
  getRooms,
  selectDisplayRooms,
  selectIsLoadingImages,
  selectRefreshRooms,
  selectRooms,
  setBooked,
  setRefreshRooms,
  setRooms,
  setSearch,
  setSort,
  setisLoadingImages,
  setRoomsNoDisplay,
  setDisplayRooms,
  selectUpdatesSeen,
  setUpdatesSeen,
  setCart,
} from './redux';
import { RoomType } from 'global/types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import { selectHotelData, selectSocket } from 'pages/redux';
import { toast } from 'react-toastify';
import { selectToken } from 'redux/global';

export const useLocalRoomUpdate = () => {
  const dispatch = useAppDispatch();
  const rooms = useAppSelector(selectRooms);
  const updatesSeen = useAppSelector(selectUpdatesSeen);
  const displayRooms = useAppSelector(selectDisplayRooms);

  const addLocalRoom = (room: any) => {
    const newRooms2 = [...(displayRooms || []), room];
    dispatch(setDisplayRooms(newRooms2));
    const newRooms = [...(rooms || []), room];
    dispatch(setRoomsNoDisplay(newRooms));
    let newUSeen = [...updatesSeen];
    newUSeen = newUSeen.filter((u) => u !== room.id.toString());
    dispatch(setUpdatesSeen(newUSeen));
  };

  const editRoomData = (roomsHere: RoomType[], editRooms: RoomType[]) => {
    const newRooms = [...(roomsHere || [])];
    editRooms.forEach((room) => {
      const id = room.id.toString() || '-1';
      let rId = -1;
      (roomsHere || []).forEach((r: any, i: number) => {
        if (r.id.toString() === id) rId = i;
      });
      if (rId > -1) {
        newRooms[rId] = { ...newRooms[rId], ...room };
      }
    });
    return newRooms;
  };

  const editLocalRoom = (editRooms: RoomType[]) => {
    dispatch(setRooms(editRoomData(rooms || [], editRooms)));
    let newUSeen = [...updatesSeen];
    editRooms.forEach((room) => {
      newUSeen = newUSeen.filter((u) => u !== room.id.toString());
    });
    dispatch(setUpdatesSeen(newUSeen));
  };

  const deleteLocalRoom = (id: any) => {
    const newRooms2 = [...(displayRooms || [])].filter((r) => r.id.toString() !== id.toString());
    dispatch(setDisplayRooms(newRooms2));
    const newRooms = [...(rooms || [])].filter((r) => r.id.toString() !== id.toString());
    dispatch(setRoomsNoDisplay(newRooms));
  };

  return {
    addLocalRoom, editRoomData, editLocalRoom, deleteLocalRoom
  };
};

export const useRooms = (
  {
    toBook,
    setLoading,
    setShowModal
  } :
  {
    toBook?: string | null,
    setLoading?: Function,
    setShowModal?: Function
  }
) => {
  const [loadingRooms, setLoadingRoomsTmp] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const refreshRooms = useAppSelector(selectRefreshRooms);
  const rooms = useAppSelector(selectRooms);
  const displayRooms = useAppSelector(selectDisplayRooms);
  const hotelData = useAppSelector(selectHotelData);
  const history = useHistory();
  const socket = useAppSelector(selectSocket);
  const token = useAppSelector(selectToken);
  const isLoadingImages = useAppSelector(selectIsLoadingImages);
  const { editLocalRoom, editRoomData } = useLocalRoomUpdate();

  const setLoadingRooms = (isLoading: boolean) => {
    setLoading?.(isLoading);
    setLoadingRoomsTmp(isLoading);
  };

  useEffect(() => {
    const fetchRoomImage = async (ids: number[]) => {
      const roomIds: string[] = [];
      const rooms3: RoomType[] = [];
      for (let i = 0; i < ids.length; i += 1) {
        const id = ids[i];
        const res = await dispatch(getRoomImage(displayRooms?.[id].id || '0'));
        if (res.status === 'success') {
          if (displayRooms && displayRooms[id].img === 'refresh' && rooms) {
            let ind = -1;
            rooms.forEach((r, j) => {
              if (r.id === displayRooms[id].id) ind = j;
            });
            if (ind > -1) {
              const newRooms = [...rooms];
              newRooms[ind] = { ...newRooms[ind], img: res.data };
              roomIds.push(newRooms[ind].id);
              rooms3.push(newRooms[ind]);
            }
          }
        }
      }
      if (ind.length) {
        // remove 'rooms' entries with ids included in 'roomIds'
        // and enter the same id enteries from 'room3' into 'rooms'
        const newRooms = [...(rooms || []).filter((r) => !roomIds.includes(r.id)), ...rooms3];
        dispatch(setRooms(newRooms));
      }
    };
    const ind: number[] = [];
    displayRooms?.forEach((room, i) => {
      if (room.img === 'refresh') {
        ind.push(i);
      }
    });
    fetchRoomImage(ind);
  }, [rooms]);

  const bookRoomDetails: BookRoomDetails[] = JSON.parse(localStorage.getItem('to_book') || '[]');

  useEffect(() => {
    if (localStorage.getItem('show_new_booking') && bookRoomDetails.length && rooms) {
      const editRooms: any = [];
      bookRoomDetails.forEach((d) => {
        if (token) {
          editRooms.push({ id: d.id, updatedAsOf: new Date() });
        } else {
          editRooms.push({ id: d.id });
        }
      });
      editLocalRoom(editRooms);
      localStorage.removeItem('to_book');
      localStorage.removeItem('show_new_booking');
      dispatch(setCart([]));
    }
  }, [rooms]);

  const bookRoomDB = async () => {
    setLoadingRooms?.(true);
    const res = await dispatch(bookRoom(bookRoomDetails));
    setLoadingRooms?.(false);

    if (res.status === 'success') {
      await socket.emit('book_room', { roomId: `room${hotelData?.id}`, room: res.data });
      res.data.forEach((d: RoomType) => {
        dispatch(setBooked(
          { id: d.id || '', token: d.bookToken || '', expires: new Date(d.freeBy) }
        ));
      });
      setShowModal?.(4);
      return;
    }
    toast(res.data, { type: 'error' });
  };

  useEffect(() => {
    const fetchImages = async (roomsHere: RoomType[]) => {
      dispatch(setisLoadingImages(true));
      const res = await dispatch(getRoomImages());
      dispatch(setisLoadingImages(false));
      if (res.status === 'success') {
        const newRooms = roomsHere.map((room, i) => { return { ...room, img: res.data[i].img }; });
        dispatch(setRooms(newRooms));
      }
    };
    const fetchRooms = async () => {
      setLoadingRooms?.(true);
      if (toBook) {
        if (toBook === 'pass') {
          if (localStorage.getItem('to_book')) {
            localStorage.setItem('show_new_booking', 'true');
            await bookRoomDB();
          } else {
            dispatch(setCart([]));
          }
          history.push('/rooms');
          dispatch(setRefreshRooms(!refreshRooms));
          return;
        } else {
          toast('Payment was not successful. ERROR: Verification failed.', { type: 'error' });
        }
      }
      const res = await dispatch(getRooms());
      setLoadingRooms?.(false);
      if (res.status === 'success') {
        dispatch(setRooms(res.data as RoomType[]));
        const newDetails: any = [];
        if (bookRoomDetails.length) {
          bookRoomDetails.forEach((d) => {
            if (token) {
              newDetails.push({ id: d.id, updatedAsOf: new Date() });
            } else {
              newDetails.push({ id: d.id });
            }
          });
        }
        fetchImages(editRoomData(res.data as RoomType[], newDetails));
        return;
      }
      dispatch(setRooms([]));
      toast(res.data, { type: 'error' });
    };
    dispatch(setSearch(''));
    dispatch(setSort('No sort. No filter'));
    if (!rooms) fetchRooms();
  }, [refreshRooms]);

  return { loadingRooms, isLoadingImages };
};
