import React, { useState } from 'react';
import * as S from './EditStaffModal.styled';
import Loader from 'pages/components/Loader/Loader';
import EscapeButton from 'pages/components/EscapeButton/EscapeButton';
import { Roles, User, rolesMain } from '../SetEmailDetails';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { editStaff } from 'pages/Settings/redux';
import { toast } from 'react-toastify';
import { selectPermission } from 'redux/global';

const EditStaffModal = function EditStaffModal(
  {
    onExit, users, selectedUser, setUsers,
  }:
  { onExit: Function, users: User[], selectedUser: User, setUsers: Function },
) {
  const permission = useAppSelector(selectPermission);
  const [loading, setLoading] = useState<boolean>(false);
  const roles: Roles[] = [...rolesMain];
  if (Number(permission) === 3) roles.push('Tech team');
  const [userType, setUserType] = useState<Roles>(roles[Number(selectedUser.permission)]);
  const [username, setUsername] = useState<string>(selectedUser.username);
  const [usernameError, setUsernameError] = useState<string>();
  const dispatch = useAppDispatch();

  const isOldUserType = userType === roles[Number(selectedUser.permission)];
  const isOldUserName = username === selectedUser.username;
  const isSaveButtonDisabled = isOldUserType && isOldUserName;

  const handleSave = async () => {
    if (!username) {
      setUsernameError('Username is required');
      return;
    }
    if (username !== selectedUser.username
      && users.find((u) => u.username.toLowerCase() === username.toLowerCase())) {
      setUsernameError('This username exists already');
      return;
    }
    setLoading(true);
    const permissionHere = roles.indexOf(userType).toString();
    const apiBody = {
      username,
      email: selectedUser.email,
      permission: permissionHere,
    };
    const res = await dispatch(editStaff(apiBody));
    setLoading(false);
    if (res.status === 'success') {
      const thisUser = users.find((u) => u.email === selectedUser.email);
      if (thisUser) {
        const id = users.indexOf(thisUser);
        const newUsers = [...users];
        newUsers[id] = apiBody;
        setUsers(newUsers);
        toast('Successfully updated staff member', { type: 'success' });
        onExit();
      } else {
        toast('An error occured', { type: 'error' });
      }
      return;
    }
    toast(res.data, { type: 'error' });
  };

  return (
    <>
      { loading ? <Loader isSticky />
        : (
          <S.Container>
            <EscapeButton onClick={() => onExit()} />
            <S.MainLabel>Edit Staff</S.MainLabel>
            <S.Label>{selectedUser.email}</S.Label>
            <S.Label1>Select role</S.Label1>
            <S.Select
              onChange={(e) => setUserType(e.target.value as ('Front desk' | 'Manager' | 'Owner'))}
            >
              {
                roles.map((userHere) => (
                  <option key={`user_${userHere}`} selected={userHere === userType}>
                    {userHere}
                  </option>
                ))
              }
            </S.Select>
            <S.Label1>Username</S.Label1>
            <S.Input
              value={username}
              error={Boolean(usernameError)}
              onChange={(e) => {
                setUsernameError('');
                setUsername(e.target.value);
                if (e.target.value !== selectedUser.username
                  && users.find((u) => u.username === e.target.value)) {
                  setUsernameError('This username exists already');
                }
              }}
            />
            { usernameError && <S.Error>{usernameError}</S.Error>}
            <S.Button1>
              <Button
                variant="outlined"
                color="inherit"
                sx={{ borderWidth: '1px' }}
                disabled={isSaveButtonDisabled}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </S.Button1>
          </S.Container>
        )}
    </>
  );
};

export default EditStaffModal;
