import styled from 'styled-components';
import { mobileRes2, topBarColor2 } from 'pages/styles';
import Color from 'color';
import { AuditName } from '../hooks.styled';

export const ContStyle = styled.div`
  display: flex;
  align-items: center;
  align-items: flex-end;
  flex: 1;
  margin-left: 70px;
  gap: 40px;
  padding: 0px 0px;
  margin-top: 0px;
  height: 100%;
`;

export const Container = styled(ContStyle)`
  @media(max-width: 466px) {
    margin-left: 60px;
    gap: 30px;
  }
  @media(max-width: 434px) {
    margin-left: 40px;
    gap: 20px;
  }
  @media(max-width: 298px) {
    margin-left: 30px;
    gap: 15px;
  }
  @media(max-width: 287px) {
    margin-left: 25px;
    gap: 10px;
  }
  @media(max-width: 276px) {
    margin-left: 15px;
    gap: 5px;
  }
`;

export const Container2 = styled(ContStyle)`
  @media(max-width: 589px) {
    gap: 30px;
  }
  @media(max-width: 559px) {
    margin-left: 50px;
    gap: 25px;
  }
  @media(max-width: 422px) {
    margin-left: 40px;
    gap: 20px;
  }
  @media(max-width: 296px) {
    margin-left: 25px;
    gap: 10px;
  }
`;

export const AuditSet = styled(AuditName)<{ isKey: boolean }>`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-end;
  gap: 7px;
  margin-top: 2px;
  font-weight: ${({ isKey }) => isKey ? '500' : '400'};
  color: ${({ isKey }) => isKey && topBarColor2};
  font-size: ${({ isKey }) => isKey ? '12px' : '11.5px'};
`;

export const AuditName1 = styled.div`
  height: max-content;
`;

export const Select = styled.input`
  height: 17px;
  width: 17px;
  margin: 0;
  transform: translateY(-1px);
  pointer-events: none;
`;

export const OptionButton = styled.div`
  padding: 10.5px 16px;
  font-size: 12.5px;
  width: max-content;
  border-radius: 5px;
  background: ${Color(topBarColor2).lighten(2.5).toString()};
  color: ${Color(topBarColor2).lighten(0.9).toString()};
  font-weight: 500;
  transform: translateY(-1px);
  margin-left: auto;
  cursor: pointer;
  &:hover {
    background: ${Color(topBarColor2).lighten(2.2).toString()};
    color: ${Color(topBarColor2).lighten(0.2).toString()};
  }
  @media(max-width: ${mobileRes2}) {
    padding: 11px 16px;
    padding-bottom: 10px;
  }
`;
